<app-container-base subTitle="Lista de Precios" (onClickBarButton)="imprimir($event)" [activeButtons]="activeButtons"
  [isReport]="true" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmListaPrecios" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <components-ui-text-box [required]="true" label='Nombre del Reporte' formControlName="NombreReporte"
                [tabIndex]="1" [widthLabelColumns]="2" [widthTextColumns]="10" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>

          <div class="row">
            <component-ui-combo-box label='Contenido' [tabIndex]="2" formControlName="Contenido" inputProperty="Nombre"
              listProperty="Nombre" [selectManualItem]="selectContenidoManualItem"
              [setItemsList]="this.listaContenidoCbo" [widthTextColumns]="2" [widthLabelColumns]="2"
              (onSelectedItem)="selectedCombo($event,'Contenido')" />
          </div>
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Filtros">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosListaPrecios" [tabIndex]="8"
            (FiltrosSeleccionChange)="changeSelect($event)"></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
