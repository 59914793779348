<app-container-base subTitle="Diario de pagos" (onClickBarButton)="clickBoton($event)" [hasCounter]="false"
  [isReport]="true" [activeButtons]="activeButtons" icon="fa-list-check">
  <div class="screen-content">
    <form [formGroup]="frmDiarioPagos" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <components-ui-text-box label='Nombre del Reporte' [tabIndex]="1" [widthLabelColumns]="2"
                [widthTextColumns]="10" [maxLength]="100" formControlName="NombreReporte" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' [widthLabelColumns]="6" [widthTextColumns]="4" [tabIndex]="2"
                formControlName="Fecha1" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' [widthLabelColumns]="3" [widthTextColumns]="4" [tabIndex]="3"
                formControlName="Fecha2" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <component-ui-check-box label='Imprimir en Moneda Nacional(pesos)' formControlName="Opc1" [tabIndex]="4"
                [widthLabelColumns]="7" [widthTextColumns]="1" />
            </div>
          </div>
        </div>
      </app-panel-base>

      <!-- <app-panel-base title="Filtros">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="9"
            (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.frmDiarioPagos"></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
