<div class="bg-light m-2" [style]="getGlobalStyle" cdkDrag style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div class="widget rounded" style="margin-bottom: 0px;" data-bs-theme="" *ngIf="title.length>0">
    <div class="widget-header" style="cursor:move;" cdkDragHandle>
      <h4 class="widget-header-title text-center">{{title}} (Antes de agregarse se valida la vigencia en el SAT)</h4>
    </div>
  </div>
  <ul class="nav nav-tabs bg-light" role="tablist" *ngIf="anio>0">
    <li class="nav-item" role="presentation"><a href="#busqueda{{anio>0?'conta':''}}" data-bs-toggle="tab"
        class="nav-link active text-dark" aria-selected="true" role="tab"><span class="d-sm-none">Tab 1</span><span
          class="d-sm-block d-none">Búsqueda</span></a></li>
    <li *ngIf="mostrarAgregados" class="nav-item" role="presentation"><a href="#agregados{{anio>0?'conta':''}}"
        data-bs-toggle="tab" class="nav-link text-dark" aria-selected="true" role="tab"><span class="d-sm-none">Tab
          1</span><span class="d-sm-block d-none">Ya agregados
          <span
            class="badge bg-gray-600 fs-10px rounded-pill ms-auto fw-bolder pt-4px pb-5px px-8px">{{listaAgregados.length}}</span>
        </span></a></li>
  </ul>
  <div class="tab-content panel p-3 rounded-0 rounded-bottom">
    <div id="busqueda{{anio>0?'conta':''}}" class="tab-pane fade active show">
      <app-container-base subTitle="" [containerInnerHeight]="403" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
        [hasCounter]="false">
        <div class="screen-content">
          <form [formGroup]="myForm" autocomplete="off">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <components-ui-text-box placeHolder="Escriba el texto a buscar en los CFDI" formControlName="busqueda"
                    [label]="''" [widthTextColumns]="12" [tabIndex]="1" [widthLabelColumns]="0" #txtBuscarCfdi />
                </div>
                <div class="col-2" style="margin-right: -45px;">
                  <components-ui-date-box formControlName="fechaInicio" [topLabel]="false" label="" separator=""
                    [tabIndex]="2" [widthLabelColumns]="0" [widthTextColumns]="8" />
                </div>
                <div class="col-2" style="margin-right: -42px;">
                  <components-ui-date-box formControlName="fechaFin" [widthLabelColumns]="2" [label]="'al'"
                    [tabIndex]="3" [topLabel]="false" [widthTextColumns]="8" />
                </div>
                <div class="col-2">
                  <component-ui-combo-box [label]="'Comp'" [zeroMask]="0" entityName="c_TipoDeComprobante"
                    [widthTextColumns]="7" [inputProperty]="'Nombre'" [widthLabelColumns]="5" [widthRightColumns]="0"
                    [tabIndex]="4" (onSelectedItem)="selectedCombo($event,'TipoDeComprobante')"
                    [listProperty]="'ClaveNombre'" />
                </div>
                <div class="col-2">
                  <div class="col-lg-12 d-lg-block d-none">
                    <a href="#" class="btn btn-white d-flex align-items-center rounded-3" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-lg fa-tags me-10px text-body text-opacity-50 d-none"></i>
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                        viewBox="0 0 24 24" data-icon="solar:tag-linear"
                        class="iconify fs-20px my-n1 me-2 ms-n1 text-theme iconify--solar">
                        <g fill="none" stroke="currentColor" stroke-width="1.5">
                          <path
                            d="M4.728 16.137c-1.545-1.546-2.318-2.318-2.605-3.321c-.288-1.003-.042-2.068.45-4.197l.283-1.228c.413-1.792.62-2.688 1.233-3.302c.614-.613 1.51-.82 3.302-1.233l1.228-.284c2.13-.491 3.194-.737 4.197-.45c1.003.288 1.775 1.061 3.32 2.606l1.83 1.83C20.657 9.248 22 10.592 22 12.262c0 1.671-1.344 3.015-4.033 5.704c-2.69 2.69-4.034 4.034-5.705 4.034c-1.67 0-3.015-1.344-5.704-4.033z">
                          </path>
                          <circle cx="8.607" cy="8.879" r="2" transform="rotate(-45 8.607 8.879)"></circle>
                          <path stroke-linecap="round" d="m11.542 18.5l6.979-6.98"></path>
                        </g>
                      </svg>
                      E/R = {{!myForm.value.emitidorecibido ? 'Todos': myForm.value.emitidorecibido}}
                      <i class="fa ms-auto fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li (click)="selectedComboEmitido('Emitidos')"><a
                          class="dropdown-item d-flex align-items-center"><i
                            class="fa fa-circle fs-7px me-2 ms-n1 text-success"></i> Emitidos</a></li>
                      <li (click)="selectedComboEmitido('Recibidos')"><a
                          class="dropdown-item d-flex align-items-center"><i
                            class="fa fa-circle fs-7px me-2 ms-n1 text-body text-opacity-25"></i> Recibidos</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-2">
                  <a (click)="searchEnter()" class="btn btn-primary btn-xs d-block">Buscar</a>
                </div>
              </div>
              <div class="row">
                <div [style]="getDivStyle">
                  <ng-scrollbar class="h-100  m-2">
                    <table class="table table-bordered table-sm table-striped mb-0 "
                      style="font-family: monospace;table-layout: fixed; ">
                      <thead style="position: sticky;top: 0; background: var(--bs-component-bg);">
                        <tr class="border-bottom">
                          <th class="text-center" style="width:30px !important;">Sel</th>
                          <th class="text-center" style="width:30px !important;">PDF</th>
                          <th class="text-center" style="width:30px !important;">XML</th>
                          <th class="text-start">Tipo</th>
                          <th class="text-start">UUID</th>
                          <th class="text-start">Emisor RFC</th>
                          <th class="text-start">Emisor Nom</th>
                          <th class="text-start">SerieFolio</th>
                          <th class="text-center">Fecha</th>
                          <th class="text-end">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of lista; let i = index;">
                          <td class="text-center"><input (click)="seleccionar(i,1)" class="form-check-input"
                              type="checkbox" /></td>
                          <td class="text-center"><i (click)="print(item.uuid)" style="cursor: pointer;"
                              class="fas fa-print fa-lg"></i>
                          </td>
                          <td class="text-center"><i (click)="downloadXML(item.uuid)" style="cursor: pointer;"
                              class="fas fa-file-code fa-lg"></i></td>
                          <td class="text-truncate">{{ item.tipoDeComprobante | uppercase }}</td>
                          <td class="text-truncate">{{item.uuid}}</td>
                          <td class="text-truncate">{{item.emisorRfc}}</td>
                          <td class="text-truncate">{{item.emisorNombre}}</td>
                          <td class="text-truncate">{{item.serieFolio}}</td>
                          <td class="text-truncate">{{item.fechaEmision}}</td>
                          <td class="text-truncate text-end">{{item.total | number:'1.2'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                  <p class="pt-4px">Solo se muestran 200 registros [mostrando: {{lista.length}}]</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </app-container-base>
    </div>
    <div *ngIf="mostrarAgregados" id="agregados{{anio>0?'conta':''}}" class="tab-pane fade"
      style="width: 900px;height: 350px;">
      <div class="card-body">
        <div class="row gx-2 pb-5px">
          <div style="border: 1px dotted;width: 907px;height: 319px;margin-left: 5px">
            <div style="text-align: right;" class="m-2">
              <div *ngIf="listaAgregados.length>0" (click)="clearAdded()" class="btn btn-danger"
                style="margin-right: 5px;">Eliminar todos</div>
            </div>

            <ng-scrollbar class="h-100  m-2">
              <table class="table table-bordered table-sm table-striped mb-0 "
                style="font-family: monospace;table-layout: fixed; ">
                <thead style="position: sticky;top: 0; background: var(--bs-component-bg);">
                  <tr class="border-bottom">
                    <th class="text-center" style="width:30px !important;"></th>
                    <th class="text-center" style="width:30px !important;">PDF</th>
                    <th class="text-center" style="width:30px !important;">XML</th>
                    <th class="text-start">Tipo Comp</th>
                    <th class="text-start">UUID</th>
                    <th class="text-start">Emisor RFC</th>
                    <th class="text-start">Emisor Nom</th>
                    <th class="text-start">SerieFolio</th>
                    <th class="text-center">Fecha</th>
                    <th class="text-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listaAgregados; let i = index;">
                    <td> <i (click)="deleteRow(i)" class="fa fa-rectangle-xmark"
                        style="color:red;font-weight: bold; cursor: pointer;"></i></td>
                    <td class="text-center"><i (click)="print(item.uuid)" style="cursor: pointer;"
                        class="fas fa-print fa-lg"></i>
                    <td> <i (click)="downloadXML(item.uuid)" style="cursor: pointer;"
                        class="fas fa-file-code fa-lg"></i></td>
                    <td class="text-truncate"> {{ item.tipoDeComprobante | uppercase }}</td>
                    <td class="text-truncate">{{item.uuid}}</td>
                    <td class="text-truncate">{{item.emisorRfc}}</td>
                    <td class="text-truncate">{{item.emisorNombre}}</td>
                    <td class="text-truncate">{{item.serieFolio}}</td>
                    <td class="text-truncate">{{item.fechaEmision}}</td>
                    <td class="text-truncate text-end"> {{item.total| number:'1.2'}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: right;">
      <button (click)="accept()" class="btn btn-success"
        style="margin-right: 5px;">Aceptar</button>
      <button (click)="cancel()" class="btn btn-danger">Cancelar</button>
    </div>
  </div>

</div>
