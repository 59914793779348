<app-container-base subTitle="Verificador de Traspasos" (onClickBarButton)="clickBoton($event)" [isReport]="true"
  [activeButtons]="activeButtons" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <components-ui-text-box label='Nombre del Reporte' [tabIndex]="1" [widthLabelColumns]="2"
              [widthTextColumns]="8" [maxLength]="100" formControlName="NombreReporte" #txtNombreReporte />
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' [widthLabelColumns]="6" [widthTextColumns]="3" [tabIndex]="2"
                formControlName="Fecha1" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' [widthLabelColumns]="3" [widthTextColumns]="3" [tabIndex]="3"
                formControlName="Fecha2" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-check-box [widthLabelColumns]="6" [widthTextColumns]="1" [tabIndex]="4"
                (onClick)="selectOpcion2($event)" label='Es entre Sucursales' formControlName="Opc4" />
            </div>
            <div class="col-md-3">
              <component-ui-check-box [widthLabelColumns]="7" [widthTextColumns]="1" [tabIndex]="4"
                (onClick)="selectOpcion1($event)" label='Es entre Almacenes' formControlName="Opc3" />
            </div>
          </div>
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Generales">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="8"
            (FiltrosSeleccionChange)="changeselec($event)" [ReportHeader]="this.myForm"></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
