import { Injectable, inject } from '@angular/core';
import { PosMenuStockPage } from '../pages/pos/menu-stock/menu-stock';
import { PosCustomerOrderPage } from '../../ventas/pages/pos-page/customer-order';
import { PosKitchenOrderPage } from '../pages/pos/kitchen-order/kitchen-order';
import { PosCounterCheckoutPage } from '../pages/pos/counter-checkout/counter-checkout';
import { ExtraProductDetailsPage } from '../pages/extra/extra-product-details/extra-product-details';
import { ExtraProductsPage } from '../pages/extra/extra-products/extra-products';
import { ExtraOrderDetailsPage } from '../pages/extra/extra-order-details/extra-order-details';
import { ExtraOrdersPage } from '../pages/extra/extra-orders/extra-orders';
import { ExtraCookieAcceptanceBannerPage } from '../pages/extra/extra-cookie-acceptance-banner/extra-cookie-acceptance-banner';
import { ExtraProfilePage } from '../pages/extra/extra-profile/extra-profile';
import { ExtraInvoicePage } from '../pages/extra/extra-invoice/extra-invoice';
import { ExtraErrorPage } from '../pages/extra/extra-error/extra-error';
import { ExtraSearchResultsPage } from '../pages/extra/extra-search-results/extra-search-results';
import { ExtraComingSoonPage } from '../pages/extra/extra-coming-soon/extra-coming-soon';
import { ExtraTimelinePage } from '../pages/extra/extra-timeline/extra-timeline';
import { CalendarPage } from '../pages/calendar/calendar';
import { PageBlank } from '../pages/page-blank/page-blank';
import { WidgetPage } from '../pages/widget/widget';
import { EmailDetailPage } from '../pages/email/detail/email-detail';
import { EmailComposePage } from '../pages/email/compose/email-compose';
import { EmailInboxPage } from '../pages/email/inbox/email-inbox';
import { LoginV3Page } from '../pages/login/login-v3/login-v3';
import { PuestoPageComponent } from '../../configuracion/pages/puesto-page/puesto-page.component';
import { UsuarioPageComponent } from '../../configuracion/pages/usuario-page/usuario-page.component';
import { EmpresaPageComponent } from '../../configuracion/pages/empresa-page/empresa-page.component';
import { PosTableBookingPage } from '../pages/pos/table-booking/table-booking';
import { SucursalPageComponent } from 'src/app/configuracion/pages/sucursales-page/sucursal-page/sucursal-page.component';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TiposDeImpuestosPageComponent } from 'src/app/ventas/pages/tipos-de-impuestos-page/tipos-de-impuestos-page.component';
import { ImpuestosPageComponent } from 'src/app/ventas/pages/impuestos-page/impuestos-page.component';
import { MonedasPageComponent } from 'src/app/ventas/pages/monedas-page/monedas-page.component';
import { ClaseVentaPageComponent } from 'src/app/ventas/pages/clase-venta-page/clase-venta-page.component';
import { TiposClientesPageComponent } from 'src/app/ventas/pages/tipos-clientes-page/tipos-clientes-page.component';
import { ClasesClientesPageComponent } from 'src/app/ventas/pages/clases-clientes-page/clases-clientes-page.component';
import { FormasPagoPageComponent } from 'src/app/ventas/pages/formas-pago-page/formas-pago-page.component';
import { CondicionesPagosPageComponent } from 'src/app/ventas/pages/condiciones-pagos-page/condiciones-pagos-page.component';
import { ClientesPageComponent } from 'src/app/ventas/pages/clientes-page/clientes-page.component';
import { RolPageComponent } from 'src/app/configuracion/pages/rol-page/rol-page.component';
import { DocumentosVentasPageComponent } from 'src/app/ventas/pages/documentos-ventas-page/documentos-ventas-page.component';
import { SeriesVentasPageComponent } from 'src/app/ventas/pages/series-ventas-page/series-ventas-page.component';
import { FacturacionPageComponent } from 'src/app/ventas/pages/facturacion-page/facturacion-page.component';
import { DiarioVentasPageComponent } from 'src/app/ventas/pages/reports/diario-ventas-page/diario-ventas-page.component';
import { VerificadorVentasPageComponent } from '../../ventas/pages/reports/verificador-ventas/verificador-ventas-page.component';
import { ProductoPageComponent } from 'src/app/Inventarios/pages/producto-page/producto-page.component';
import { FacturaGlobalPageComponent } from 'src/app/ventas/pages/factura-global-page/factura-global-page.component';
import { ImportacionPageComponent } from 'src/app/sistema/pages/importacion-page/importacion-page.component';
import { CuentasCxcPageComponent } from 'src/app/cuentas-por-cobrar/pages/cuentas-cxc-page/cuentas-cxc-page.component';
import { ConceptoCxcPageComponent } from 'src/app/cuentas-por-cobrar/pages/concepto-cxc-page/concepto-cxc-page.component';
import { ListaPrecioComponent } from 'src/app/ventas/pages/lista-precio/lista-precio.component';
import { CargaListaPrecioComponent } from 'src/app/ventas/pages/carga-lista-precio/carga-lista-precio.component';
import { EstadoCuentaCxcPageComponent } from 'src/app/cuentas-por-cobrar/pages/estado-cuenta-cxc-page/estado-cuenta-cxc-page.component';
import { HistoricoDocumentoPageComponent } from 'src/app/cuentas-por-cobrar/pages/historico-documento-page/historico-documento-page.component';
import { RelacionesAnaliticasCxcPageComponent } from 'src/app/cuentas-por-cobrar/pages/reports/relaciones-analiticas/relaciones-analiticas-cxc-page.component';
import { ConfigWebTicketPageComponent } from 'src/app/webticket/pages/config-web-ticket-page/config-web-ticket-page.component';
import { AnticiposFacturadosPageComponent } from 'src/app/ventas/pages/reports/anticipos-facturados-page/anticipos-facturados-page.component';
import { EnviarMetaDataPageComponent } from 'src/app/efisco/pages/enviar-meta-data-page/enviar-meta-data-page.component';
import { UtilsService } from 'src/app/service/utils.service';
import { FamiliasComponent } from 'src/app/Inventarios/pages/familias/familias.component';
import { AntiguedadSaldosPageComponent } from 'src/app/cuentas-por-cobrar/pages/reports/antiguedad-saldos/antiguedad-saldos-cxc-page.component';
import { AlmacenesPageComponent } from 'src/app/Inventarios/pages/almacenes-page/almacenes-page.component';
import { ConceptosAlmacenesPageComponent } from 'src/app/Inventarios/pages/conceptos-almacenes-page/conceptos-almacenes-page.component';
import { CuentasBancariasPageComponent } from 'src/app/Finanzas/pages/cuentas-bancarias-page/cuentas-bancarias-page.component';
import { ChoferesPageComponent } from 'src/app/ventas/pages/choferes-page/choferes-page.component';
import { UnidadesPageComponent } from 'src/app/ventas/pages/unidades-page/unidades-page.component';
import { TipoMovimientoCxcPageComponent } from 'src/app/cuentas-por-cobrar/pages/tipo-movimiento-cxc-page/tipo-movimiento-cxc-page.component';
import { TipoMovimientoAlmacenPageComponent } from '../../Inventarios/pages/tipo-movimiento-almacen-page/tipo-movimiento-almacen-page.component';
import { CuentaCxpPageComponent } from 'src/app/cuentas-por-pagar/pages/cuenta-cxp-page/cuenta-cxp-page.component';
import { ConceptoCxpPageComponent } from 'src/app/cuentas-por-pagar/pages/concepto-cxp-page/concepto-cxp-page.component';
import { ClasificacionProductoPageComponent } from 'src/app/Inventarios/pages/clasificacion-producto-page/clasificacion-producto-page.component';
import { CajaPageComponent } from 'src/app/ventas/pages/caja-page/caja-page.component';
import { AperturaCajaPageComponent } from 'src/app/ventas/pages/apertura-caja-page/apertura-caja-page.component';
import { ClasesProveedoresPageComponent } from 'src/app/compras/pages/clases-proveedores-page/clases-proveedores-page.component';
import { ClasesComprasPageComponent } from 'src/app/compras/pages/clases-compras-page/clases-compras-page.component';
import { CondicionesPagoProveedoresPageComponent } from 'src/app/compras/pages/condiciones-pago-proveedores-page/condiciones-pago-proveedores-page.component';
import { TiposProveedoresPageComponent } from 'src/app/compras/pages/tipos-proveedores-page/tipos-proveedores-page.component';
import { TiposDocumentosComprasPageComponent } from 'src/app/compras/pages/tipos-documentos-compras-page/tipos-documentos-compras-page.component';
import { SeriesComprasPageComponent } from 'src/app/compras/pages/series-compras-page/series-compras-page.component';
import { ProveedoresPageComponent } from 'src/app/compras/pages/proveedores-page/proveedores-page.component';
import { ComprasPageComponent } from 'src/app/compras/pages/compras-page/compras-page.component';
import { DiarioComprasPageComponent } from 'src/app/compras/pages/reports/diario-compras-page/diario-compras-page.component';
import { AntiguedadSaldosCxpPageComponent } from 'src/app/cuentas-por-pagar/pages/reports/antiguedad-saldos-cxp-page/antiguedad-saldos-cxp-page.component';
import { VerificadorComprasPageComponent } from 'src/app/compras/pages/reports/verificador-compras-page/verificador-compras-page.component';
import { CapturaPagosCxcPageComponent } from 'src/app/cuentas-por-cobrar/pages/captura-pagos-cxc/captura-pagos-cxc.page.component';
import { KardexPageComponent } from 'src/app/Inventarios/pages/kardex-page/kardex-page.component';
import { TipoPolizaPageComponent } from 'src/app/contabilidad/pages/tipo-poliza-page/tipo-poliza-page.component';
import { CuentaContablePageComponent } from 'src/app/contabilidad/pages/cuenta-contable-page/cuenta-contable-page.component';
import { CentroCostoPageComponent } from 'src/app/contabilidad/pages/centro-costo-page/centro-costo-page.component';
import { RelacionesAnaliticasCxpPageComponent } from 'src/app/cuentas-por-pagar/pages/reports/relaciones-analiticas-cxp-page/relaciones-analiticas-cxp-page.component';
import { ActivoFijoPageComponent } from 'src/app/contabilidad/pages/activo-fijo-page/activo-fijo-page.component';
import { CostoVentasPageComponent } from 'src/app/Inventarios/pages/costo-ventas-page/costo-ventas-page.component';
import { VerificadorCargosAbonosCXCComponent } from 'src/app/cuentas-por-cobrar/pages/reports/verificador-cargos-abonos-cxc/verificador-cargos-abonos-cxc.component';
import { VerificadorCargosAbonosCXPComponent } from 'src/app/cuentas-por-pagar/pages/reports/verificador-cargos-abonos-cxp/verificador-cargos-abonos-cxp.component';
import { CapturaPolizaPageComponent } from 'src/app/contabilidad/pages/captura-poliza-page/captura-poliza-page.component';
import { PolizaAutomaticaPageComponent } from 'src/app/contabilidad/pages/poliza-automatica-page/poliza-automatica-page.component';
import { ImpresionListaPreciosPageComponent } from 'src/app/ventas/pages/reports/impresion-lista-precios-page/impresion-lista-precios-page.component';
import { RubrosPageComponent } from 'src/app/contabilidad/pages/rubros-page/rubros-page.component';
import { DiarioGeneralPageComponent } from 'src/app/contabilidad/pages/diario-general.page/diario-general.page.component';
import { EstadoCuentaCxpPageComponent } from 'src/app/cuentas-por-pagar/pages/estado-cuenta-cxp-page/estado-cuenta-cxp-page.component';
import { SolicitudDescargaPageComponent } from 'src/app/efisco/pages/solicitud-descarga-page/solicitud-descarga-page.component';
import { RelacionesAnaliticasComponent } from 'src/app/contabilidad/pages/reportes/relaciones-analiticas/relaciones-analiticas-page.component';
import { AlmacenCfdiPageComponent } from 'src/app/efisco/pages/almacen-cfdi-page/almacen-cfdi-page.component';
import { EstadoDeCuentaContablePageComponent } from 'src/app/contabilidad/pages/estado-de-cuenta-contable-page/estado-de-cuenta-contable-page.component';
import { MayorGeneralComponent } from 'src/app/contabilidad/pages/reportes/mayor-general/mayor-general-page.component';
import { VerificadorDePolizasPageComponent } from 'src/app/contabilidad/pages/reportes/verificador-de-polizas-page/verificador-de-polizas-page.component';
import { XmlCatalogoCuentasComponent } from 'src/app/contabilidad/pages/xml-catalogo-cuentas/xml-catalogo-cuentas.component';
import { AceptarRechazarPageComponent } from 'src/app/efisco/pages/aceptar-rechazar-page/aceptar-rechazar-page.component';
import { CalculadoraDepreciacionPageComponent } from '../../contabilidad/pages/calculadora-depreciacion-page/calculadora-depreciacion-page.component';
import { ConfigPrometeoPageComponent } from 'src/app/Finanzas/pages/config-prometeo-page/config-prometeo-page.component';
import { SaldosBancosPageComponent } from 'src/app/Finanzas/pages/saldos-bancos-page/saldos-bancos-page.component';
import { BuscarMovimientosBancariosPageComponent } from 'src/app/Finanzas/pages/buscar-movimientos-bancarios-page/buscar-movimientos-bancarios-page.component';
import { CartaPortePageComponent } from 'src/app/ventas/pages/carta-porte-page/carta-porte-page.component';
import { UsuariosConectadosPageComponent } from 'src/app/sistema/pages/usuarios-conectados-page/usuarios-conectados-page.component';
import { ConfigVentasPageComponent } from 'src/app/ventas/pages/config-ventas-page/config-ventas-page.component';
import { TiposMovimientosCxpPageComponent } from '../../cuentas-por-pagar/pages/tipos-movimientos-cxp-page/tipos-movimientos-cxp-page.component';
import { MenuFavoritosUsuario } from 'src/app/components/interfaces/menu-favoritos-usuario.interface';
import { Menu } from 'src/app/components/interfaces/menu.interface';
import { TipoServicioUnidadPageComponent } from 'src/app/ventas/pages/tipo-servicio-unidad-page/tipo-servicio-unidad-page.component';
import { RegistroActividadComponent } from '../../sistema/pages/registroActividad/registro-actividad-page.component';
import { TipoTransferenciaPageComponent } from 'src/app/Finanzas/pages/tipo-transferencia-page/tipo-transferencia-page.component';
import { TipoCajaChicaPageComponent } from 'src/app/Finanzas/pages/tipo-caja-chica-page/tipo-caja-chica-page.component';
import { CajaChicaPageComponent } from 'src/app/Finanzas/pages/caja-chica-page/caja-chica-page.component';
import { ClaseGastosCajaChicaPageComponent } from 'src/app/Finanzas/pages/clase-gastos-caja-chica-page/clase-gastos-caja-chica-page.component';
import { TiposGastosPageComponent } from 'src/app/Finanzas/pages/tipos-gastos-page/tipos-gastos-page.component';
import { GastosPageComponent } from 'src/app/Finanzas/pages/gastos-page/gastos-page.component';
import { PredefinidosProveedoresPageComponent } from 'src/app/efisco/pages/predefinidos-proveedores-page/predefinidos-proveedores-page.component';
import { ChequePosfechadoPageComponent } from 'src/app/cuentas-por-cobrar/pages/cheque-posfechado-page/cheque-posfechado-page.component';
import { SerieTraspasoPageComponent } from 'src/app/Inventarios/pages/serie-traspaso-page/serie-traspaso-page.component';
import { ReporteCajaChicaPageComponent } from 'src/app/Finanzas/pages/reportes/reporteCajaChicaPage/reporte-caja-chica-page.component';
import { MaximosMinimosPageComponent } from 'src/app/Inventarios/pages/maximos-minimos-page/maximos-minimos-page.component';
import { CatalogoClientesPageComponent } from 'src/app/ventas/pages/reports/catalogo-clientes/catalogo-clientes-page.component';
import { ListadoChequesPostfechadosPageComponent } from 'src/app/cuentas-por-cobrar/pages/reports/listado-cheques-postfechados-page/listado-cheques-postfechados-page.component';
import { VerificadorGastosPageComponent } from 'src/app/Finanzas/pages/reportes/verificador-gastos-page/verificador-gastos-page.component';
import { SeriesManufacturaPageComponent } from 'src/app/Manufactura/pages/series-manufactura-page/series-manufactura-page.component';
import { OrdenesManufacturaPageComponent } from 'src/app/Manufactura/pages/ordenes-manufactura-page/ordenes-manufactura-page.component';
import { ConfiguracionManufacturaPageComponent } from 'src/app/Manufactura/pages/configuracion-manufactura-page/configuracion-manufactura-page.component';
import { VerificadorOrdenesManufacturaPageComponent } from 'src/app/Manufactura/pages/reportes/verificador-ordenes-manufactura-page/verificador-ordenes-manufactura-page.component';
import { TableroOrdnesPageComponent } from 'src/app/Manufactura/pages/tablero-ordnes-page/tablero-ordnes-page.component';
import { SeriesResguardosPageComponent } from 'src/app/resguardos/pages/series-resguardos-page/series-resguardos-page.component';
import { RemisionesResguardosPageComponent } from 'src/app/resguardos/pages/remisiones-resguardos-page/remisiones-resguardos-page.component';
import { TraspasoSucursalesPageComponent } from 'src/app/Inventarios/pages/traspaso-sucursales-page/traspaso-sucursales-page.component';
import { VerificadorTraspasosPageComponent } from 'src/app/Inventarios/pages/reportes/verificador-traspasos-page/verificador-traspasos-page.component';
import { ConfiguracionResguardosPageComponent } from 'src/app/resguardos/pages/configuracion-resguardos-page/configuracion-resguardos-page.component';
import { VerificadorRemisionesResguardosComponent } from 'src/app/resguardos/pages/reportes/verificador-remisiones-resguardos/verificador-remisiones-resguardos.component';
import { TraspasoAlmacenesPageComponent } from 'src/app/Inventarios/pages/traspaso-almacenes-page/traspaso-almacenes-page.component';
import { PresupuestosVendedoresPageComponent } from 'src/app/ventas/pages/presupuestos-vendedores-page/presupuestos-vendedores-page.component';
import { PresupuestosSucursalesPageComponent } from 'src/app/ventas/pages/presupuestos-sucursales-page/presupuestos-sucursales-page.component';
import { TipoDeCambioPageComponent } from 'src/app/ventas/pages/tipo-de-cambio-page/tipo-de-cambio-page.component';
import { TipoCapturaInvFisicoPageComponent } from 'src/app/Inventarios/pages/tipo-captura-inv-fisico-page/tipo-captura-inv-fisico-page.component';
import { ConfiguracionInvFisicoPageComponent } from 'src/app/Inventarios/pages/configuracion-inv-fisico-page/configuracion-inv-fisico-page.component';
import { InventarioFisicoComponent } from 'src/app/Inventarios/pages/inventario-fisico-page/inventario-fisico-page.component';
import { ConfiguracionTraspasoPageComponent } from 'src/app/Inventarios/pages/configuracion-traspaso-page/configuracion-traspaso-page.component';
import { RecepcionTraspasoPageComponent } from 'src/app/Inventarios/pages/recepcion-traspaso-page/recepcion-traspaso-page.component';
import { ImpresionConteoComponent } from 'src/app/Inventarios/pages/impresion-conteo/impresion-conteo.component';
import { SeriesRepPageComponent } from 'src/app/ventas/pages/series-rep-page/series-rep-page.component';
import { MovimientoscxcPageComponent } from 'src/app/cuentas-por-cobrar/pages/movimientoscxc-page/movimientoscxc-page.component';
import { ActCatSATPageComponent } from 'src/app/sistema/pages/act-cat-satpage/act-cat-satpage.component';
import { ReciboElecPagoPageComponent } from 'src/app/ventas/pages/recibo-elec-pago-page/recibo-elec-pago-page.component';
import { BancosPageComponent } from 'src/app/Finanzas/pages/bancos-page/bancos-page.component';
import { SeriesRetencionesPageComponent } from 'src/app/efisco/pages/series-retenciones-page/series-retenciones-page.component';
import { ConstanciaPagosPageComponent } from 'src/app/efisco/pages/constancia-pagos-page/constancia-pagos-page.component';
import { ConstanciaReceptoresPageComponent } from 'src/app/efisco/pages/constancia-receptores-page/constancia-receptores-page.component';
import { CargaEntregaPageComponent } from 'src/app/ventas/pages/carga-entrega-page/carga-entrega-page.component';
import { ConfigCargaEntregaPageComponent } from 'src/app/ventas/pages/config-carga-entrega-page/config-carga-entrega-page.component';
import { OrdenesCargaPageComponent } from 'src/app/ventas/pages/ordenes-carga-page/ordenes-carga-page.component';
import { ExistenciasPageComponent } from 'src/app/Inventarios/pages/existencias-page/existencias-page.component';
import { BalanceGeneralPageComponent } from 'src/app/contabilidad/pages/reportes/balance-general-page/balance-general-page.component';
import { RubrosERPageComponent } from 'src/app/contabilidad/pages/rubros-erpage/rubros-erpage.component';
import { XmlBalanzaMensualPageComponent } from 'src/app/contabilidad/pages/xml-balanza-mensual-page/xml-balanza-mensual-page.component';
import { PlantillasCartaPortePageComponent } from 'src/app/ventas/pages/plantillas-carta-porte-page/plantillas-carta-porte-page.component';
import { BalanzaComprobacionPageComponent } from 'src/app/contabilidad/pages/reportes/balanza-comprobacion-page/balanza-comprobacion-page.component';
import { DepartamentosPageComponent } from 'src/app/configuracion/pages/departamentos-page/departamentos-page.component';
import { NeumaticosPageComponent } from 'src/app/ventas/pages/neumaticos-page/neumaticos-page.component';
import { UbicacionNeumaticoPageComponent } from 'src/app/ventas/pages/ubicacion-neumatico-page/ubicacion-neumatico-page.component';
import { DiarioPagosComponent } from 'src/app/cuentas-por-cobrar/pages/reports/diario-pagos/diario-pagos.component';
import { CargosExtrasComprasPageComponent } from 'src/app/compras/pages/cargos-extras-compras-page/cargos-extras-compras-page.component';
import { PaqueteriasPageComponent } from 'src/app/configuracion/pages/paqueterias-page/paqueterias-page.component';
import { EstadoResultadosPageComponent } from '../../contabilidad/pages/reportes/estado-resultados-page/estado-resultados-page.component';
import { ExistenciasPaquetesComponent } from 'src/app/Inventarios/pages/existencias-paquetes-page/existencias-paquetes-page.component';
import { BalanceGeneralComparativoComponent } from 'src/app/contabilidad/pages/reportes/balance-general-comparativo/balance-general-comparativo.component';
import { HistoricoPaquetesPageComponent } from 'src/app/Inventarios/pages/historico-paquetes.page/historico-paquetes.page.component';
import { DiarioRepsPageComponent } from 'src/app/ventas/pages/reports/diario-reps-page/diario-reps-page.component';
import { DescargaXmlCaptchaPageComponent } from 'src/app/efisco/pages/descarga-xml-captcha-page/descarga-xml-captcha-page.component';
import { VerificadorCargosAdicionalesPageComponent } from 'src/app/compras/pages/reports/verificador-cargos-adicionales-page/verificador-cargos-adicionales-page.component';
import { AdminCfdiPageComponent } from 'src/app/efisco/pages/admin-cfdi-page/admin-cfdi-page.component';
import { ConfigConciliacionPageComponent } from 'src/app/Finanzas/pages/config-conciliacion-page/config-conciliacion-page.component';
import { EstadoCuentaConciliacionPageComponent } from 'src/app/Finanzas/pages/estado-cuenta-conciliacion-page/estado-cuenta-conciliacion-page.component';
import { MediosEntradaPageComponent } from 'src/app/crm/pages/medios-entrada-page/medios-entrada-page.component';
import { EstoadosProspectoPageComponent } from 'src/app/crm/pages/estoados-prospecto-page/estoados-prospecto-page.component';
import { ConfiguracionCrmPageComponent } from 'src/app/crm/pages/configuracion-crm-page/configuracion-crm-page.component';
import { VistasCrmPageComponent } from 'src/app/crm/pages/vistas-crm-page/vistas-crm-page.component';
import { ProspectosCrmPageComponent } from 'src/app/crm/pages/prospectos-crm-page/prospectos-crm-page.component';
import { LlamadasPageComponent } from 'src/app/crm/pages/llamadas-page/llamadas-page.component';
import { VerificadorFacturasEntregadasADomicilioComponent } from 'src/app/ventas/pages/reports/verificador-facturas-entregadas-a-domicilio/verificador-facturas-entregadas-a-domicilio.component';
import { TokenPreciosPageComponent } from 'src/app/tokens/pages/token-precios/token-precios.page.component';
import { TipoGastoUnidadesComponent } from 'src/app/ventas/pages/tipo-gasto-unidades/tipo-gasto-unidades.component';
import { TokenCreditoPageComponent } from 'src/app/tokens/pages/token-credito/token-credito.page.component';
import { VerificadorComprasPorSurtirPageComponent } from 'src/app/compras/pages/reports/verificador-compras-por-surtir-page/verificador-compras-por-surtir-page.component';
import { AuditoriaTokensPageComponent } from 'src/app/tokens/pages/auditoria-tokens/auditoria-tokens.page.component';
import { DiotGeneracionComponent } from 'src/app/Finanzas/pages/diot-generacion/diot-generacion.component';
import { TipoProductoPageComponent } from 'src/app/Inventarios/pages/tipo-producto-page/tipo-producto-page.component';
import { VerificadorDiotComponent } from 'src/app/Finanzas/pages/verificador-diot/verificador-diot.component';
import { MovimientosInventarioComponent } from 'src/app/Inventarios/pages/movimientos-inventario/movimientos-inventario.component';
import { UtileriasComplitPageComponent } from 'src/app/sistema/pages/utilerias-complit-page/utilerias-complit-page.component';
import { MotivoDiferenciaPageComponent } from 'src/app/Inventarios/pages/motivo-diferencia-page/motivo-diferencia-page.component';
import { VerificadorInventarioPageComponent } from 'src/app/Inventarios/pages/verificador-inventario-page/verificador-inventario-page.component';
import { TerminalesBancariasPageComponent } from 'src/app/Finanzas/pages/terminales-bancarias-page/terminales-bancarias-page.component';
import { CorteCajaPageComponent } from 'src/app/ventas/pages/corte-caja-page/corte-caja-page.component';
import { TiposRetirosComponent } from 'src/app/ventas/pages/tipos-retiros/tipos-retiros.component';
import { BloqueoModulosPageComponent } from 'src/app/configuracion/pages/bloqueo-modulos-page/bloqueo-modulos-page.component';
import { ClientesPorVendedorComponent } from 'src/app/ventas/pages/clientes-por-vendedor/clientes-por-vendedor.component';
import { CancelacionDocumentosVentasPageComponent } from 'src/app/ventas/pages/cancelacion-documentos-ventas-page/cancelacion-documentos-ventas-page.component';
import { VerificadorTonalejeCargaEntregaComponent } from 'src/app/ventas/pages/verificador-tonaleje-carga-entrega/verificador-tonaleje-carga-entrega.component';
import { PredefinidosClientesPageComponent } from 'src/app/ventas/pages/predefinidos-clientes-page/predefinidos-clientes-page.component';
import { VerificadorSalidasPorVentaComponent } from 'src/app/ventas/pages/reports/verificador-salidas-por-venta/verificador-salidas-por-venta.component';
import { TipoProduccionPageComponent } from 'src/app/Manufactura/pages/tipo-produccion-page/tipo-produccion-page.component';
import { DisenosProduccionPageComponent } from 'src/app/Manufactura/pages/disenos-produccion-page/disenos-produccion-page.component';
import { CapturaProduccionPageComponent } from 'src/app/Manufactura/pages/captura-produccion-page/captura-produccion-page.component';
import { TiposDocumentosInventariosPageComponent } from 'src/app/Inventarios/pages/tipos-documentos-inventarios-page/tipos-documentos-inventarios-page.component';
import { SeriesInventariosPageComponent } from 'src/app/Inventarios/pages/series-inventarios-page/series-inventarios-page.component';
import { VerificadorResguardosPorSurtidoPorSurtirComponent } from 'src/app/resguardos/pages/reportes/verificador-resguardos-por-surtido-por-surtir/verificador-resguardos-por-surtido-por-surtir.component';
import { TransferenciasPageComponent } from 'src/app/Finanzas/pages/transferencias-page/transferencias-page.component';
import { ChequesPageComponent } from 'src/app/Finanzas/pages/cheques-page/cheques-page.component';
import { VerificadorTransferenciasComponent } from 'src/app/Finanzas/pages/verificador-transferencias/verificador-transferencias.component';
import { VerificadorChequesComponent } from 'src/app/Finanzas/pages/verificador-cheques/verificador-cheques.component';
import { AuditoriaTokensCreditoComponent } from 'src/app/tokens/pages/auditoria-tokens-credito/auditoria-tokens-credito.component';
import { VerificadorOrdenesComponent } from 'src/app/Manufactura/pages/verificador-ordenes/verificador-ordenes.component';
import { MovimientosCxpComponent } from 'src/app/cuentas-por-pagar/movimientos-cxp/movimientoscxp.component';
import { HistoricoFacturasComponent } from 'src/app/cuentas-por-cobrar/pages/reports/historico-facturas/historico-facturas.component';
import { InfoGeneralProductoPageComponent } from 'src/app/ventas/pages/info-general-producto-page/info-general-producto-page.component';
import { MasVendidosComponent } from 'src/app/ventas/pages/reports/mas-vendidos/mas-vendidos.component';
import { IngresosPorPeriodoComponent } from 'src/app/cuentas-por-cobrar/pages/reports/ingresos-por-periodo/ingresos-por-periodo.component';
import { EmisionDocumentoInventarioPageComponent } from 'src/app/Inventarios/pages/emision-documento-inventario-page/emision-documento-inventario-page.component';
import { VerificadorDocumentosInventarioComponent } from 'src/app/Inventarios/pages/verificador-documentos-inventario/verificador-documentos-inventario.component';
import { InfoGeneralClientePageComponent } from 'src/app/ventas/pages/info-general-cliente/info-general-cliente-page.component';
import { UbicacionesProdTermPageComponent } from 'src/app/Manufactura/pages/ubicaciones-prod-term-page/ubicaciones-prod-term-page.component';
import { UnidadesMedidaPageComponent } from 'src/app/Inventarios/pages/unidades-medida-page/unidades-medida-page.component';
import { TiposValesPageComponent } from 'src/app/Finanzas/pages/tipos-vales-page/tipos-vales-page.component';
import { PersonasValesPageComponent } from 'src/app/Finanzas/pages/personas-vales-page/personas-vales-page.component';
import { CapturaValesPageComponent } from 'src/app/Finanzas/pages/captura-vales-page/captura-vales-page.component';
import { ConciliacionManualPageComponent } from 'src/app/Finanzas/pages/conciliacion-manual-page/conciliacion-manual-page.component';
import { VerificadorEstadosCuentasComponent } from 'src/app/Finanzas/pages/verificador-estados-cuentas/verificador-estados-cuentas.component';
import { AuditoriaCancelacionPageComponent } from 'src/app/efisco/pages/auditoria-cancelacion-page/auditoria-cancelacion-page.component';
import { AuditoriaPpdPageComponent } from 'src/app/efisco/pages/auditoria-ppd-page/auditoria-ppd-page.component';
import { InformeUtilidadPageComponent } from 'src/app/ventas/pages/reports/informe-utilidad-page/informe-utilidad-page.component';
import { VerificadorValesComponent } from 'src/app/Finanzas/pages/verificador-vales/verificador-vales.component';
import { AuditoriaRepsPageComponent } from 'src/app/efisco/pages/auditoria-reps-page/auditoria-reps-page.component';






@Injectable({
  providedIn: 'root'
})


export class AppMenuService {
  private readonly baseUrl: string = environment.baseUrlApi;
  private http = inject(HttpClient);
  private utilsService = inject(UtilsService);

  /**
   * Busca el elemento seleccionado del menú para poder obtener la Pantalla seleccionada.
   * @param item Elemento seleccionado del menú.
   * @returns Elemento encontrado dentro de la lista de rutas del menú.
   */
  getItemOpenPages(item: any) {
    let child: any = this.getComponentPages().find((c: any) => c.url === item.url);
    return child;
  }

  getComponentPages() {
    return [
      { url: 'empresas', component: EmpresaPageComponent, title: 'Empresas' },
      { url: 'usuarios', component: UsuarioPageComponent, title: 'Usuarios' },
      { url: 'puestos', component: PuestoPageComponent, title: 'Puestos' },
      { url: 'sucursales', component: SucursalPageComponent, title: 'Sucursales' },
      { url: 'roles', component: RolPageComponent, title: 'Roles' },
      { url: 'departamentos', component: DepartamentosPageComponent, title: 'Departamentos' },
      { url: 'paqueterias', component: PaqueteriasPageComponent, title: 'Paqueterías' },
      { url: 'bloqueomodulos', component: BloqueoModulosPageComponent, title: 'Bloqueo de Módulos' },


      //#region Tokens
      { url: 'tokenprecios', component: TokenPreciosPageComponent, title: 'Token Para Precios' },
      { url: 'tokencredito', component: TokenCreditoPageComponent, title: 'Token Para Crédito' },
      { url: 'tokensauditoria', component: AuditoriaTokensPageComponent, title: 'Auditoría de Tokens de Precios' },
      { url: 'tokensauditoriacredito', component: AuditoriaTokensCreditoComponent, title: 'Auditoría de Tokens de Créditos' },
      //#endregion


      //CRM
      { url: 'mediosentrada', component: MediosEntradaPageComponent, title: 'Medios de Entrada' },
      { url: 'estadosprospectos', component: EstoadosProspectoPageComponent, title: 'Estados de Prospectos' },
      { url: 'configurarcrm', component: ConfiguracionCrmPageComponent, title: 'Configurar CRM' },
      { url: 'vistacrm', component: VistasCrmPageComponent, title: 'Visitas' },
      { url: 'prospectos', component: ProspectosCrmPageComponent, title: 'Prospectos' },
      { url: 'llamadas', component: LlamadasPageComponent, title: 'Llamadas' },

      { url: 'cancelacionventas', component: CancelacionDocumentosVentasPageComponent, title: 'Cancelación Documentos VENTAS' },
      { url: 'seriesdeventas', component: SeriesVentasPageComponent, title: 'Series de Ventas' },
      { url: 'diarioventas', component: DiarioVentasPageComponent, title: 'Diario de Ventas' },
      { url: 'masvendidos', component: MasVendidosComponent, title: 'Mas vendidos' },
      { url: 'informeutiildades', component: InformeUtilidadPageComponent, title: 'Informe de Utilidad' },




      { url: 'diarioreps', component: DiarioRepsPageComponent, title: 'Diario de Recibos Electrónicos de Pagos (REPs)' },
      { url: 'verificadordeventas', component: VerificadorVentasPageComponent, title: 'Verificador de Ventas' },
      { url: 'anticiposfacturados', component: AnticiposFacturadosPageComponent, title: 'Anticipos Facturados' },
      { url: 'clientesporvendedor', component: ClientesPorVendedorComponent, title: 'Clientes por Vendedor' },
      { url: 'configemisdocu', component: ConfigVentasPageComponent, title: 'Configuración de Emisión de Ventas' },
      { url: 'tiposserviciosunidades', component: TipoServicioUnidadPageComponent, title: 'Tipos de Servicios' },
      { url: 'impresion-catalogo-clientes', component: CatalogoClientesPageComponent, title: 'Impresión de Catálogo de Clientes' },
      { url: 'presvendedores', component: PresupuestosVendedoresPageComponent, title: 'Presupuesto de Ventas por Vendedor ' },
      { url: 'pressucursales', component: PresupuestosSucursalesPageComponent, title: 'Presupuesto de Ventas por Sucursal' },
      { url: 'tipocambio', component: TipoDeCambioPageComponent, title: 'Tipo de Cambio' },
      { url: 'reciboelec', component: ReciboElecPagoPageComponent, title: 'Elaboración de Recibo Electrónico de Pago' },
      { url: 'configentregas', component: ConfigCargaEntregaPageComponent, title: 'Configuración Entregas En Domicilio' },
      { url: 'ordenesdecarga', component: OrdenesCargaPageComponent, title: 'Consulta y Re-impresión de Ordenes de Carga' },
      { url: 'verificadorordenescarga', component: VerificadorFacturasEntregadasADomicilioComponent, title: 'Verificador de Facturas Entregadas' },
      { url: 'tonelajecargaentrega', component: VerificadorTonalejeCargaEntregaComponent, title: 'Verificador Toneladas en Ordenes de Carga' },

      { url: 'tiposdedocumentos', component: DocumentosVentasPageComponent, title: 'Tipos de Documentos' },
      { url: 'tiposdeimpuestos', component: TiposDeImpuestosPageComponent, title: 'Tipos de Impuestos' },
      { url: 'tiposdeclientes', component: TiposClientesPageComponent, title: 'Tipos de Clientes' },
      { url: 'formasdepago', component: FormasPagoPageComponent, title: 'Formas de Pago' },
      { url: 'clasesdeclientes', component: ClasesClientesPageComponent, title: 'Clases de Clientes' },
      { url: 'clientes', component: ClientesPageComponent, title: 'Clientes' },
      { url: 'condicionesdepago', component: CondicionesPagosPageComponent, title: 'Condiciones de Pago' },
      { url: 'productos', component: ProductoPageComponent, title: 'Productos' },


      { url: 'monedas', component: MonedasPageComponent, title: 'Monedas' },
      { url: 'impuestos', component: ImpuestosPageComponent, title: 'Impuestos' },
      { url: 'clasesventas', component: ClaseVentaPageComponent, title: 'Clases de Ventas' },
      { url: 'predefinidosclientes', component: PredefinidosClientesPageComponent, title: 'Predefinidos de Alta de Clientes' },
      { url: 'posorder', component: PosCustomerOrderPage, title: 'Punto de Venta' },
      { url: 'poscaja', component: CajaPageComponent, title: 'Cajas' },
      { url: 'poscajaapertura', component: AperturaCajaPageComponent, title: 'Apertura De Caja' },
      { url: 'cortecaja', component: CorteCajaPageComponent, title: 'Corte de Caja (Diario)' },
      { url: 'tiposretiros', component: TiposRetirosComponent, title: 'Tipos de Retiros' },

      { url: 'facturacion', component: FacturacionPageComponent, title: 'Facturas, Pedidos y más' },
      { url: 'plantillacartaporte', component: PlantillasCartaPortePageComponent, title: 'Plantillas Carta Porte' },
      { url: 'cartaporte', component: CartaPortePageComponent, title: 'Carta Porte' },

      { url: 'facturaglobal', component: FacturaGlobalPageComponent, title: 'Factura Global' },
      { url: 'infogeneralproducto', component: InfoGeneralProductoPageComponent, title: 'Información General Por Producto' },
      { url: 'infogeneralcliente', component: InfoGeneralClientePageComponent, title: 'Información General Por Cliente' },

      { url: 'choferes', component: ChoferesPageComponent, title: 'Choferes' },
      { url: 'vehiculos', component: UnidadesPageComponent, title: 'Vehículos' },
      { url: 'neumaticos', component: NeumaticosPageComponent, title: 'Neumáticos' },
      { url: 'neumaticosubicaciones', component: UbicacionNeumaticoPageComponent, title: 'Ubicaciones de Neumáticos' },
      { url: 'tiposgastossunidades', component: TipoGastoUnidadesComponent, title: 'Tipos de Gasto' },

      { url: 'listasprecios', component: ListaPrecioComponent, title: 'Listas de precios' },
      { url: 'mantenimientolistas', component: CargaListaPrecioComponent, title: 'Carga de Listas' },
      { url: 'impresion-lista-precios', component: ImpresionListaPreciosPageComponent, title: 'Impresión Listas de Precios' },

      { url: 'tiposmovimientoscxc', component: TipoMovimientoCxcPageComponent, title: 'Tipos de movimientos de CXC' },
      { url: 'cuentascxc', component: CuentasCxcPageComponent, title: 'Cuentas de CXC' },
      { url: 'conceptoscxc', component: ConceptoCxcPageComponent, title: 'Conceptos de CXC' },
      { url: 'estadodecuentacxc', component: EstadoCuentaCxcPageComponent, title: 'Estado de Cuenta de Cuentas Por Cobrar' },
      { url: 'movscxc', component: MovimientoscxcPageComponent, title: 'Movimientos de Cuentas por Cobrar' },
      { url: 'historicocxc', component: HistoricoDocumentoPageComponent, title: 'Histórico de Documento de Cuentas Por Cobrar' },
      { url: 'chequeposfechado', component: ChequePosfechadoPageComponent, title: 'Captura de Cheques Posfechados' },

      { url: 'relacionesanaliticascxc', component: RelacionesAnaliticasCxcPageComponent, title: 'Relaciones Analíticas de Cuentas Por Cobrar' },
      { url: 'antiguedadsaldoscxc', component: AntiguedadSaldosPageComponent, title: 'Antig. de Saldos Ctas. Por Cobrar' },
      { url: 'diariopagos', component: DiarioPagosComponent, title: 'Diario de pagos' },
      { url: 'historicofacturas', component: HistoricoFacturasComponent, title: 'Histórico de Facturas' },
      { url: 'ingresosperiodo', component: IngresosPorPeriodoComponent, title: 'Ingresos por Período' },
      { url: 'verificadorcargosabonoscxc', component: VerificadorCargosAbonosCXCComponent, title: 'Verificador de Cargos y Abonos de CxC' },
      { url: 'listadochequesposfechados', component: ListadoChequesPostfechadosPageComponent, title: 'Verificador De Cheques Postfechados' },

      { url: 'seriesresguardo', component: SeriesResguardosPageComponent, title: 'Series Remisiones de Resguardos' },
      { url: 'seriesderep', component: SeriesRepPageComponent, title: 'Series para Recibos Electrónicos de Pago' },
      { url: 'remisionresguardo', component: RemisionesResguardosPageComponent, title: 'Remisiones de Resguardos' },
      { url: 'configuracionresguardo', component: ConfiguracionResguardosPageComponent, title: 'Configuración de Resguardos' },
      { url: 'verificadorremisionesresguardo', component: VerificadorRemisionesResguardosComponent, title: 'Verificador de Remisiones por Resguardo' },
      { url: 'resguardospendientesporsurtir', component: VerificadorResguardosPorSurtidoPorSurtirComponent, title: 'Resguardos Surtidos/Por Surtir' },

      { url: 'importarregistros', component: ImportacionPageComponent, title: 'Importar Registros' },
      { url: 'utileriascomplit', component: UtileriasComplitPageComponent, title: 'Utilerias Complit' },
      { url: 'usuarios-conectados', component: UsuariosConectadosPageComponent, title: 'Usuarios Conectados' },
      { url: 'registro-actividad', component: RegistroActividadComponent, title: 'Registro de Actividad' },
      { url: 'actualizacatsat', component: ActCatSATPageComponent, title: 'Actualizar Catálogos del SAT' },

      { url: 'clasesdeproveedores', component: ClasesProveedoresPageComponent, title: 'Clases de Proveedores' },
      { url: 'clasescompras', component: ClasesComprasPageComponent, title: 'Clases de compras' },
      { url: 'condicionesdepagoproveedores', component: CondicionesPagoProveedoresPageComponent, title: 'Condiciones de Pago Proveedores' },
      { url: 'tiposdeproveedores', component: TiposProveedoresPageComponent, title: 'Tipos de Proveedores' },
      { url: 'tiposdedocumentoscompras', component: TiposDocumentosComprasPageComponent, title: 'Tipos de Documentos de Compras' },
      { url: 'seriesdecompras', component: SeriesComprasPageComponent, title: 'Series de Compras' },
      { url: 'proveedores', component: ProveedoresPageComponent, title: 'Proveedores' },
      { url: 'compras', component: ComprasPageComponent, title: 'Compras' },
      { url: 'diariocompras', component: DiarioComprasPageComponent, title: 'Diario de Compras' },
      { url: 'cargosadiccompras', component: VerificadorCargosAdicionalesPageComponent, title: 'Verificador Cargos Adicionales' },
      { url: 'verificadordecompras', component: VerificadorComprasPageComponent, title: 'Verificador de Compras' },
      { url: 'cargosadicionalescompras', component: CargosExtrasComprasPageComponent, title: 'Cargos Adicionales Compras' },
      { url: 'verificadordecomprasporsurtir', component: VerificadorComprasPorSurtirPageComponent, title: 'Verificador de Compras por Surtir' },

      { url: 'configwebticket', component: ConfigWebTicketPageComponent, title: 'Configurar Web Ticket (CEPDI)' },
      { url: 'efiscosenddata', component: EnviarMetaDataPageComponent, title: 'Enviar CFDI a efisco' },
      { url: 'auditoriacancelaciones', component: AuditoriaCancelacionPageComponent, title: 'Auditoría de CFDIS Cancelados' },
      { url: 'auditoriappds', component: AuditoriaPpdPageComponent, title: 'Auditoría de Cfdis PPD' },
      { url: 'auditoriareps', component: AuditoriaRepsPageComponent, title: 'Auditoría de Recibos Electrónicos de Pago' },
      { url: 'admindata', component: AdminCfdiPageComponent, title: 'Administrador de CFDIs' },
      { url: 'almacencfdi', component: AlmacenCfdiPageComponent, title: 'Almacén de CFDI' },
      { url: 'buzontributario', component: AceptarRechazarPageComponent, title: 'Aceptar/Rechazar' },
      { url: 'predefinidosprovefisco', component: PredefinidosProveedoresPageComponent, title: 'Predefinidos Proveedores' },
      { url: 'seriesretenciones', component: SeriesRetencionesPageComponent, title: 'Series de Constancias de Pagos y Retenciones' },
      { url: 'constanciapagos', component: ConstanciaPagosPageComponent, title: 'Emisión de Constancias de Pagos y Retenciones' },
      { url: 'receptorconctancia', component: ConstanciaReceptoresPageComponent, title: 'Receptores para la Constancias de Pagos y Retenciones' },

      { url: 'emisiondocumentoinventario', component: EmisionDocumentoInventarioPageComponent, title: 'Emisión de Documentos de Inventario' },
      { url: 'tiposmovimientosalmacen', component: TipoMovimientoAlmacenPageComponent, title: 'Tipos de Movimientos de Almacén' },
      { url: 'motivosdiferencias', component: MotivoDiferenciaPageComponent, title: 'Motivos de Diferencias Inventario Físico' },
      { url: 'familias', component: FamiliasComponent, title: 'Familias' },
      { url: 'clasificacionproducto', component: ClasificacionProductoPageComponent, title: 'Clasificaciones de Productos' },
      { url: 'tipoproducto', component: TipoProductoPageComponent, title: 'Tipos de Productos' },
      { url: 'existenciaspaquetes', component: ExistenciasPaquetesComponent, title: 'Existencias Paquetes/Lotes' },
      { url: 'historicopaquetes', component: HistoricoPaquetesPageComponent, title: 'Histórico Paquetes/Lotes' },
      { url: 'almacenes', component: AlmacenesPageComponent, title: 'Almacenes' },
      { url: 'conceptosalmacenes', component: ConceptosAlmacenesPageComponent, title: 'Conceptos de Almacén' },
      { url: 'verificadordocinventario', component: VerificadorDocumentosInventarioComponent, title: ' Verificador de Documentos de Inventario' },
      { url: 'kardex', component: KardexPageComponent, title: 'Kárdex' },
      { url: 'impresion-conteo', component: ImpresionConteoComponent, title: 'Impresión para Conteo' },
      { url: 'existencias', component: ExistenciasPageComponent, title: 'Existencias' },
      { url: 'costo-ventas', component: CostoVentasPageComponent, title: 'Costo de Ventas' },
      { url: 'seriestraspasos', component: SerieTraspasoPageComponent, title: 'Series de Traspasos' },
      { url: 'maximosminimos', component: MaximosMinimosPageComponent, title: 'Máximos, Mínimos y Puntos de Reorden' },
      { url: 'traspasosucursales', component: TraspasoSucursalesPageComponent, title: 'Traspaso entre Sucursales' },
      { url: 'traspasosalmacenes', component: TraspasoAlmacenesPageComponent, title: 'Traspaso entre Almacenes' },
      { url: 'configtraspaso', component: ConfiguracionTraspasoPageComponent, title: 'Configuración de Traspasos' },
      { url: 'tiposcapinvfis', component: TipoCapturaInvFisicoPageComponent, title: 'Tipos de Capturas de Inv. Físico' },
      { url: 'configinvfisico', component: ConfiguracionInvFisicoPageComponent, title: 'Configuración de Inv. Físico' },
      { url: 'entregascomprobantes', component: CargaEntregaPageComponent, title: 'Entregas a Domicilio' },
      { url: 'verificadorsalidasporventa', component: VerificadorSalidasPorVentaComponent, title: 'Verificador de Salidas por Venta' },
      { url: 'tiposdocumentosinventarios', component: TiposDocumentosInventariosPageComponent, title: 'Tipos de Documentos de Inventarios' },
      { url: 'seriesinventarios', component: SeriesInventariosPageComponent, title: 'Series de Inventarios' },


      { url: 'inventariofisico', component: InventarioFisicoComponent, title: 'Captura De Inventario Físico' },
      { url: 'movimientosinventario', component: MovimientosInventarioComponent, title: 'Captura De Movimientos de Inventario' },
      { url: 'recepciontraspaso', component: RecepcionTraspasoPageComponent, title: 'Recepción De Traspasos En Tránsito' },
      { url: 'verificadormovinventario', component: VerificadorInventarioPageComponent, title: 'Verificador de Movs. de Inventario' },


      { url: 'verificadortraspasos', component: VerificadorTraspasosPageComponent, title: 'Verificador de Traspasos' },

      { url: 'capturapagoscxc', component: CapturaPagosCxcPageComponent, title: 'Captura de Pagos CxC' },

      { url: 'tiposmovimientoscxp', component: TiposMovimientosCxpPageComponent, title: 'Tipos de Movimientos de CxP.' },
      { url: 'cuentascxp', component: CuentaCxpPageComponent, title: 'Cuentas de CxP.' },
      { url: 'conceptoscxp', component: ConceptoCxpPageComponent, title: 'Conceptos de CXP.' },
      { url: 'estadodecuentacxp', component: EstadoCuentaCxpPageComponent, title: 'Estado de Cuenta de Cuentas Por Pagar' },
      { url: 'movimientoscxp', component: MovimientosCxpComponent, title: 'Movimientos de Cuentas por Pagar' },

      { url: 'seriesmanufactura', component: SeriesManufacturaPageComponent, title: 'Series de Producción' },
      { url: 'unidadesmedida', component: UnidadesMedidaPageComponent, title: 'Unidades de Medida SAT' },
      { url: 'ubicacionprodterm', component: UbicacionesProdTermPageComponent, title: 'Ubicaciones de Producto Terminado' },
      { url: 'capturaproduccion', component: CapturaProduccionPageComponent, title: 'Captura de Producción' },
      { url: 'tipoproduccion', component: TipoProduccionPageComponent, title: 'Tipos de Producciónes' },
      { url: 'disenosproduccion', component: DisenosProduccionPageComponent, title: 'Diseños de Producción' },
      { url: 'ordenesmanufactura', component: OrdenesManufacturaPageComponent, title: 'Pre-Ordenes de Producción' },
      { url: 'configuracionmanufactura', component: ConfiguracionManufacturaPageComponent, title: 'Configuración de Producción' },
      { url: 'verificadorordenesmanufactura', component: VerificadorOrdenesManufacturaPageComponent, title: 'Pendientes Por Producir' },
      { url: 'verificadorordenes', component: VerificadorOrdenesComponent, title: 'Verificador de Ordenes' },
      { url: 'tableromanufactura', component: TableroOrdnesPageComponent, title: 'Tablero de Ordenes' },


      { url: 'antiguedadsaldoscxp', component: AntiguedadSaldosCxpPageComponent, title: 'Antig. de Saldos Ctas. por Pagar' },
      { url: 'relacionesanaliticascxp', component: RelacionesAnaliticasCxpPageComponent, title: 'Relaciones analíticas de Cuentas Por Pagar' },
      { url: 'verificadorcargosabonoscxp', component: VerificadorCargosAbonosCXPComponent, title: 'Verificador de cargos y abonos de CxP.' },

      { url: 'cuentasbancarias', component: CuentasBancariasPageComponent, title: 'Cuentas Bancarias' },
      { url: 'configuracionprometeo', component: ConfigPrometeoPageComponent, title: 'Configuración Prometeo API' },
      { url: 'configConciliacion', component: ConfigConciliacionPageComponent, title: 'Configuración De Conciliación' },
      { url: 'estadocuentaconciliacion', component: EstadoCuentaConciliacionPageComponent, title: 'Carga de Estados de Cuenta Para Conciliación de Sistema vs Banco' },
      { url: 'conciliacionmanual', component: ConciliacionManualPageComponent, title: 'Conciliación Manual de Sistema vs Banco' },
      { url: 'conciliacionverificador', component: VerificadorEstadosCuentasComponent, title: 'Verificador de Estados de Cuenta' },
      { url: 'diot-generacion', component: DiotGeneracionComponent, title: 'Generación DIOT' },
      { url: 'diot-verificador', component: VerificadorDiotComponent, title: 'Verificador DIOT' },

      { url: 'tipoasvales', component: TiposValesPageComponent, title: 'Tipos de Vales' },
      { url: 'personas', component: PersonasValesPageComponent, title: 'Personas Vales' },
      { url: 'vales', component: CapturaValesPageComponent, title: 'Captura de Vales' },
      { url: 'verificadorvales', component: VerificadorValesComponent, title: 'Verificador de Vales' },

      { url: 'saldosbancos', component: SaldosBancosPageComponent, title: 'Saldos de bancos en línea' },
      { url: 'transferencias', component: TransferenciasPageComponent, title: 'Transferencias Bancarias' },
      { url: 'cheques', component: ChequesPageComponent, title: 'Emisión de Cheques' },
      { url: 'buscarmovimientos', component: BuscarMovimientosBancariosPageComponent, title: 'Actualizar movimientos bancarios' },
      { url: 'tipostransferencias', component: TipoTransferenciaPageComponent, title: 'Tipos de Transferencia' },
      { url: 'tiposcajachica', component: TipoCajaChicaPageComponent, title: 'Tipos de Caja Chica' },
      { url: 'terminalesbancarias', component: TerminalesBancariasPageComponent, title: 'Terminales Bancarias' },
      { url: 'cajachica', component: CajaChicaPageComponent, title: 'Caja Chica' },
      { url: 'reportecajachica', component: ReporteCajaChicaPageComponent, title: 'Reporte de Caja Chica' },
      { url: 'clasesgastoscajachica', component: ClaseGastosCajaChicaPageComponent, title: 'Clase de Gastos' },
      { url: 'tiposgastos', component: TiposGastosPageComponent, title: 'Tipos de Gastos' },
      { url: 'capturagastos', component: GastosPageComponent, title: 'Captura de Gastos' },
      { url: 'verificadorgastos', component: VerificadorGastosPageComponent, title: 'Verificador de Gastos' },
      { url: 'bancossat', component: BancosPageComponent, title: 'Bancos' },
      { url: 'verificadortransferencias', component: VerificadorTransferenciasComponent, title: 'Verificador de Transferencias' },
      { url: 'verificadorcheques', component: VerificadorChequesComponent, title: 'Verificador de Cheques' },

      { url: 'tipospolizas', component: TipoPolizaPageComponent, title: 'Tipos de Pólizas' },
      { url: 'centroscostos', component: CentroCostoPageComponent, title: 'Centros de Costos' },
      { url: 'cuentascontables', component: CuentaContablePageComponent, title: 'Cuentas Contables' },
      { url: 'cuentasactivos', component: ActivoFijoPageComponent, title: 'Activos Fijos' },
      { url: 'capturapolizas', component: CapturaPolizaPageComponent, title: 'Captura de Pólizas' },
      { url: 'polizaautomatica', component: PolizaAutomaticaPageComponent, title: 'Pólizas Automáticas' },
      { url: 'rubros', component: RubrosPageComponent, title: 'Config. Balance' },
      { url: 'rubroser', component: RubrosERPageComponent, title: 'Config. Edo Resultados' },
      { url: 'conteleccatcuentas', component: XmlCatalogoCuentasComponent, title: 'XML Catálogo de Cuentas' },
      { url: 'contelecbalanzamensual', component: XmlBalanzaMensualPageComponent, title: 'XML Balanza Mensual' },
      { url: 'simuladordepreciacion', component: CalculadoraDepreciacionPageComponent, title: 'Calculadora de Depreciación' },

      { url: 'diario-general', component: DiarioGeneralPageComponent, title: 'Diario General' },
      { url: 'relacionesanaliticas', component: RelacionesAnaliticasComponent, title: 'Relaciones Analíticas' },
      { url: 'mayorgeneral', component: MayorGeneralComponent, title: 'Mayor general' },
      { url: 'estadodecuentacontable', component: EstadoDeCuentaContablePageComponent, title: 'Estado de Cuenta Contable' },
      { url: 'verificadordepolizas', component: VerificadorDePolizasPageComponent, title: 'Verificador de Pólizas' },
      { url: 'balance-general', component: BalanceGeneralPageComponent, title: 'Balance' },
      { url: 'balanza-comprobacion', component: BalanzaComprobacionPageComponent, title: 'Balanza de Comprobación' },
      { url: 'estado-resultados', component: EstadoResultadosPageComponent, title: 'Estado de Resultados' },
      { url: 'balance-comparativo', component: BalanceGeneralComparativoComponent, title: 'Balance Comparativo' },

      { url: 'solicituddescarga', component: SolicitudDescargaPageComponent, title: 'Solicitar Descargas SAT' },
      { url: 'descargasat', component: DescargaXmlCaptchaPageComponent, title: 'Descargas SAT con Captcha' },

      { url: 'login/v3', component: LoginV3Page, title: 'Login V3 Page' },

      { url: 'email/inbox', component: EmailInboxPage, title: 'Email Inbox' },
      { url: 'email/compose', component: EmailComposePage, title: 'Email Compose' },
      { url: 'email/detail', component: EmailDetailPage, title: 'Email Detail' },

      { url: 'widget', component: WidgetPage, title: 'Widgets' },

      { url: 'page-blank', component: PageBlank, title: 'Blank Page' },

      { url: 'calendar', component: CalendarPage, title: 'Calendar' },

      { url: 'extra/timeline', component: ExtraTimelinePage, title: 'Timeline' },
      { url: 'extra/coming-soon', component: ExtraComingSoonPage, title: 'Coming Soon Page' },
      { url: 'extra/search-results', component: ExtraSearchResultsPage, title: 'Search Results Page' },
      { url: 'extra/error-page', component: ExtraErrorPage, title: 'Error Page' },
      { url: 'extra/invoice', component: ExtraInvoicePage, title: 'Invoice' },
      { url: 'extra/profile', component: ExtraProfilePage, title: 'Profile Page' },
      { url: 'extra/cookie-acceptance-banner', component: ExtraCookieAcceptanceBannerPage, title: 'Cookie Acceptance Banner' },
      { url: 'extra/orders', component: ExtraOrdersPage, title: 'Orders' },
      { url: 'extra/order-details', component: ExtraOrderDetailsPage, title: 'Order Details' },
      { url: 'extra/products', component: ExtraProductsPage, title: 'Products' },
      { url: 'extra/product-details', component: ExtraProductDetailsPage, title: 'Product Details' },

      { url: 'pos/counter-checkout', component: PosCounterCheckoutPage, title: 'POS - Counter Checkout' },
      { url: 'pos/kitchen-order', component: PosKitchenOrderPage, title: 'POS - Kitchen Order' },
      { url: 'pos/customer-order', component: PosCustomerOrderPage, title: 'POS - Customer Order' },
      { url: 'pos/menu-stock', component: PosMenuStockPage, title: 'POS - Menu Stock' },
      { url: 'pos/table-booking', component: PosTableBookingPage, title: 'POS - Table Booking' },

      { url: 'sin-url', component: PageBlank, title: 'En proceso de pruebas' },
    ]
  }

  guardarMenuFavorito(nombreUsuario: string, menu: string, guardar: boolean) {
    return this.http.post<any>(`${this.baseUrl}/autorizacion/guardarMenu`, { nombreUsuario, menu, guardar });
  }

  limpiarFavs(favoritos: any) {
    return this.http.post<any>(`${this.baseUrl}/autorizacion/limpiarFavoritos`, favoritos);
  }

  getFavoritos(nombreUsuario: string) {
    const params = new HttpParams().set("nombreUsuario", nombreUsuario)
    return this.http.get<any[]>(`${this.baseUrl}/autorizacion/obtenerFavoritos`, { params });
  }

  getAppMenusPermisosUsuarios() {
    let userLogged = this.utilsService.getUserLogged();
    return this.http.get<MenuFavoritosUsuario>(`${this.baseUrl}/config/ObtenerMenuUsuario/${userLogged.nombreUsuario}`);
  }

  getAppMenusPermisosCompleto() {
    return this.http.get<Menu[]>(`${this.baseUrl}/config/ObtenerMenuUsuarioCompleto`);
  }

  getAppMenus() {
    return this.http.get<Menu[]>(`${this.baseUrl}/config/ObtenerMenu`);
  }
}
