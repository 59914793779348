<app-container-base subTitle="Diario de REPs" (onClickBarButton)="clickBoton()" [activeButtons]="activeButtons"
  [isReport]="true" icon="fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmDiarioReps" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="col-md-7">
            <components-ui-text-box helpLine="Nombre para el encabezado del reporte impreso." label='Nombre del Reporte'
              formControlName="NombreReporte" [tabIndex]="1" [widthLabelColumns]="4" [widthTextColumns]="8"
              [maxLength]="100" #txtNombreReporte />
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Del' formControlName="Fecha1" [tabIndex]="2" [widthLabelColumns]="7"
                [widthTextColumns]="3" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Al' formControlName="Fecha2" [tabIndex]="3" [widthLabelColumns]="3"
                [widthTextColumns]="3" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
          </div>

          <div class="col-md-7">
            <component-ui-combo-box label='Contenido' [tabIndex]="6" [zeroMask]="2" [setItemsList]="this.contenidos"
              [tabIndex]="3" [widthTextColumns]="4" [widthLabelColumns]="4"
              (onSelectedItem)="selectedCombo($event,'Tipo')" inputProperty="ClaveNombre" listProperty="ClaveNombre"
              [selectManualItem]="selectDetalleItem" formControlName="Num1"
              helpLine="Tipo del detalle para la impresión del reporte." />
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-filter fa-lg fa-fw me-1"></i>
            Seleccione tipo de fecha a filtrar (Por la fecha de emisión del REP o por la fecha de pago.)
          </div>
          <div class="col-md-7">
            <component-ui-check-box helpLine="Indica el filtro por fechas de emisión." label='Por fecha emisión'
              formControlName="Opc1" [tabIndex]="4" [widthLabelColumns]="4" [widthTextColumns]="3"
              (onClick)="onClickCheckBox('opc1',$event)" />
          </div>
          <div class="col-md-7">
            <component-ui-check-box helpLine="Indica el filtro por fechas de pago." label='Por fecha pago'
              formControlName="Opc2" [tabIndex]="6" [widthLabelColumns]="4" [widthTextColumns]="3"
              (onClick)="onClickCheckBox('opc2',$event)" />
          </div>
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Generales">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosDiarioReps" [tabIndex]="7"
            (FiltrosSeleccionChange)="changeselec($event)"></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
