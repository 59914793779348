import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/service/modal.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'opcion-cancelar-cxp-page',
  templateUrl: './opcion-cancelar-page.component.html',

})
export class OpcionCancelarCxpageComponent {


  @Input()
  idTraspaso: number = 0;

  private readonly baseUrl: string = environment.baseUrlApi;


  public myForm: FormGroup = this.fb.group({
    Fecha: [Date, Validators.required],
    MotivoCancelacion: ['', Validators.required],
  })

  constructor(private fb: FormBuilder,
    private mService: ModalService) {
  }

  accept() {
    if (!this.myForm.value.MotivoCancelacion) {
      Swal.fire({ text: `Indique el motivo de cancelación.`, icon: 'error', })
      return;
    }
    this.mService.closeModal(this.myForm.value);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  ngOnInit(): void {
    const ent = {
      Fecha: new Date(),
      MotivoCancelacion: '',
      MotivoSAT: { Id: 0 }
    };
    this.myForm.reset(ent);
  }




 }
