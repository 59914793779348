<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="true" [setIsNew]="esNuevo"
  [getLast]="false" subTitle="Captura de Inventario Fisico" (onClickBarButton)="onClickBarButton($event)"
  [activeButtons]="activeButtons">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base *ngIf="sourceProducts.length>0 && cords" title="Búsqueda de Productos"
        style="position: absolute; z-index:9;margin-left:150px;margin-top:80px;width:1050px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Producto</th>
                  <th style="font-weight:700;color:#ffffff;width:80px;" class="pt-0 pb-0">Familia</th>
                  <th style="font-weight:700;color:#ffffff;width:80px;" class="pt-0 pb-0">Unidad</th>
                  <th style="font-weight:700;color:#ffffff;width:50px;text-align: center;" class="pt-0 pb-0">Existencia
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaProducto($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceProducts; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Familia!.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Unidad.Nombre}}</td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Existencia | number:'1.2-6'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <app-panel-base *ngIf="sourceMotivo.length>0 && cords" title="Búsqueda de Motivos de Diferencias"
        style="position: absolute; z-index:9;margin-left:1000px;margin-top:80px;width:300px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:150px;" class="pt-0 pb-0">Motivo</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaMotivos($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceMotivo; let i = index;">
                  <td [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':'')"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>

      </app-panel-base>
      <div class="d-flex">
        <app-panel-base title="Información de la Captura de Inventario Físico" [style]="'height:180px;'"
        [itemsMenuDropDown]="menuItems" (onClicMenu)="clicMenu($event)">
          <div class="panel-content d-flex flex-column" style="height: 40%;">
            <div class="mb-3">
              <div class="d-flex">
                <component-ui-combo-box style="width:30%; margin-right: 3px;" formControlName="Almacen"
                  [topLabel]="true" label="Almacén" [inputProperty]="'Nombre'" [zeroMask]="3" [entityName]="'Almacen'"
                  [tabIndex]="1" [widthLabelColumns]="12" [widthTextColumns]="12"
                  (onSelectedItem)="selectAlmacen($event)" [widthRightColumns]="0" [required]="true" #almacenInv
                  [enabled]="getBlockCombos" helpLine="Almacén de captura de Inventario Fisico." />

                <component-ui-combo-box style="width:30%; margin-right: 5px;" formControlName="Tipo" [topLabel]="true"
                  label="Tipo captura" [inputProperty]="'Nombre'" [zeroMask]="3"
                  [entityName]="'TipoCapturaInventarioFisico'" [tabIndex]="2" [widthLabelColumns]="12"
                  [widthTextColumns]="12" (onSelectedItem)="selectTipoCaptura($event)" [widthRightColumns]="0"
                  [required]="true" #TipoCapturaInv [enabled]="getBlockCombos"
                  helpLine="Tipo de captura, nos sirve como agrupador." />

                <components-ui-number-box style="width:10%; margin-right: 10px;" [topLabel]="true"
                  formControlName="Codigo" label='Folio' [tabIndex]="3" [widthLabelColumns]="12" [widthTextColumns]="12"
                  (blur)="blurFolio($event)" #txtFolioInv
                  helpLine="Folio a emitir o Folio a buscar, indique el número de folio para buscar o bien, presione el botón Nuevo de la barra de botones." />

                <components-ui-date-box label="Emisión" [tabIndex]="4" formControlName="Fecha" [required]="true"
                  [widthLabelColumns]="8" [widthTextColumns]="6" [topLabel]="true" [enabled]="this.myForm.value.Id == 0"
                  (KeyDown.enter)="enterFecha()" helpLine="Es la Fecha de emisión de la captura de Inventario Fisico."
                  #txtFechaEmisionInfFis />

                <components-ui-date-box label="Aplicación" style="margin-left: 5px;" [tabIndex]="5"
                  formControlName="FechaAplicacion" [widthLabelColumns]="8" [widthTextColumns]="6" [enabled]="false"
                  [topLabel]="true" helpLine="Es la Fecha de emisión de la captura de Inventario Fisico." />

                <input (change)="cargarInvFisico($event)" [accept]="'.csv,.CSV'" id="fileUpload" type="file"
                  style="display: none;" #txtFileInvFisico />
              </div>
            </div>

            <div class="d-flex">
              <div style="width:28%; align-items:start;">
                <div class="mt-3">
                  <component-ui-check-box [label]="'Para aplicación global'" formControlName="EsGlobal" [tabIndex]="6"
                    [widthLabelColumns]="8" [widthTextColumns]="1" #esGlobalcheckBox (KeyDown.enter)="enterCheckBox()"
                    (KeyDown.tab)="tabCheckBox($event)" [enabled]="permiteAplicacionGlobal" />
                </div>
              </div>
              <div style="width:10%;"></div>
              <div style="width:62%;">
                <div class="widget-list mb-0" style="display: flex; justify-content: flex-end;"
                  *ngIf="myForm.value.UsuarioCaptura">
                  <div class="widget-list-item">
                    <div class="widget-list-media">
                      <i class="fas fa-user-check fa-lg text-green"></i>
                    </div>
                    <div class="widget-list-content">
                      <h4 class="widget-list-title">{{myForm.value.UsuarioCaptura.Nombre}}</h4>
                      <p class="widget-list-desc">Usuario elaboró</p>
                    </div>
                  </div>
                  <div class="widget-list-item" *ngIf="myForm.value.UsuarioAplica">
                    <div class="widget-list-media">
                      <i class="fas fa-stamp fa-lg text-success"></i>
                    </div>
                    <div class="widget-list-content">
                      <h3 class="widget-list-title">{{myForm.value.UsuarioAplica.Nombre}}</h3>
                      <p class="widget-list-desc">Usuario aplica</p>
                    </div>
                  </div>
                  <div class="widget-list-item" *ngIf="myForm.value.UsuarioCancela">
                    <div class="widget-list-media">
                      <i class="fas fa-user-xmark fa-lg text-red"></i>
                    </div>
                    <div class="widget-list-content">
                      <h3 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h3>
                      <p class="widget-list-desc">Usuario cancela</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-panel-base>
        <app-panel-base title="Opciones" [style]="'height:180px;width:232px'" *ngIf="this.myForm.value.Id > 0 ">
          <div class="panel-content">
            <div class="d-flex" style="width:100%;flex-direction: column;">
              <div *ngIf="!myForm.value.FechaCancelacion && !myForm.value.FechaAplicacion"
                class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
              <div *ngIf="!myForm.value.FechaCancelacion && myForm.value.FechaAplicacion"
                class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
              <div *ngIf="myForm.value.FechaCancelacion" class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-circle-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelado</div>
              <div (click)="openCancel()" *ngIf="!myForm.value.FechaCancelacion"
                class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>
              <div (click)="openAplicar()" *ngIf="permiteAplicar" class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-check-double t-plus-1 text-success fa-fw fa-lg"></i> Aplicar</div>
            </div>
          </div>
        </app-panel-base>

      </div>
      <app-panel-base title="Conceptos" [style]="'width:1195px; '">
        <div class="panel-content">
          <ng-scrollbar style="height: 300px;border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 50px;"></th>
                  <th style="width: 65px;">Producto</th>
                  <th style="width: 200px;">Descripción</th>
                  <th style="width: 90px;">Unidad</th>
                  <th style="width: 70px;text-align:right;padding-right:4px;">Costo</th>
                  <th style="width: 70px;min-width: 70px;max-width: 70px;text-align:right;padding-right:1px;">Conteo
                  </th>
                  <th style="width: 150px;">Conversión</th>
                  <th style="width: 70px;">Existencia</th>
                  <th style="width: 70px;">Por Surtir</th>
                  <th style="width: 70px;">Diferencia</th>
                  <th style="width: 60px;text-align:right;padding-right:4px;">Motivo</th>
                  <th style="width: 190px;">Descripción</th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                  (keydown)="keyDownRow($event, index)" *ngFor="let item of source; let index = index;">
                  <td style="text-align: center;">
                    <i (click)="deleteRow(index)" *ngIf="item.Producto" class="fa fa-rectangle-xmark fa-1x"
                      style="color:red;font-weight: bold; cursor: pointer;"></i>
                  </td>
                  <td>
                    <input [disabled]="loading" (keyup)="buscarCatalogo($event,'Producto')"
                      (keydown.escape)="escapeProducto($event)" (keydown.arrowup)="up($event,0)"
                      (keydown)="navegarProductos($event)" (keydown.arrowdown)="down($event,0)"
                      (keydown.enter)="enterProducto($event)" class="grid-editor text-dark"
                      (keydown.tab)="enterProducto($event)" [id]="'txt_'+index+'_0' + idUnico"
                      (focus)="focusProducto(index, $event, item)" [style]="'width:65px;text-align:right;height:22px;'"
                      (blur)="blurProducto($event)" type="text" [value]="item.Producto ? item.Producto.Clave: ''" />
                  </td>
                  <td>
                    <span style="margin-left: 3px;">{{item.ProdDescripcion}}</span>
                  </td>
                  <td class="text-dark">
                    <span style="margin-left: 3px;"> {{item.UnidadDescripcion}}</span>
                  </td>
                  <td class="text-lg-end pe-1">
                    <span>{{!this.noVerCostos? (item.Costo > 0 ? (item.Costo| number:'1.2-6'):''):''}}</span>
                  </td>
                  <td>
                    <input *ngIf="item.Producto" [disabled]="loading" (keydown)="keyDownConteo($event)"
                      (keydown.escape)="escapeConteo($event)" (keydown.arrowup)="up($event,4)"
                      (keydown.arrowdown)="down($event,4)" (keydown.enter)="enterConteo($event)"
                      class="grid-editor text-dark" [id]="'txt_'+index+'_4' + idUnico" (blur)="blurConteo($event)"
                      (focus)="setIndexEdit(index, $event, item)" type="number"
                      [style]="'width:70px;text-align:right;height:22px;'" [value]="item.Producto ? item.Conteo: 0" />
                  </td>
                  <td>
                    <a *ngIf="item.TextoConversiones" class="alert-link text-dark" (click)="conversionClick(index)"
                      style="cursor:pointer;margin-left: 3px;">{{item.TextoConversiones}} </a>
                  </td>
                  <td class="text-lg-end pe-1">
                    <span>{{item.Existencia != 0 ? (item.Existencia| number:'1.2-6'):''}}</span>
                  </td>
                  <td class="text-lg-end pe-1">
                    <span>{{item.PorSurtir != 0 ? (item.PorSurtir| number:'1.2-6'):''}}</span>
                  </td>
                  <td class="text-lg-end pe-1">
                    <span>{{item.Diferencia != 0 ? (item.Diferencia| number:'1.2-6'):''}}</span>
                  </td>
                  <td>
                    <input *ngIf="item.Producto" [disabled]="loading" (keyup)="buscarCatalogo($event,'Motivo')"
                      (keydown.escape)="escapeMotivo($event)" (keydown.arrowup)="up($event,9)"
                      (keydown)="navegarMotivo($event)" (keydown.arrowdown)="down($event,9)"
                      (keydown.enter)="enterMotivo($event)" class="grid-editor text-dark"
                      (keydown.tab)="enterMotivo($event)" [id]="'txt_'+index+'_9' + idUnico"
                      (focus)="focusMotivo(index, $event, item)" (blur)="blurMotivo($event)"
                      [style]="'width:100%;text-align:right;height:22px;'" type="text"
                      [value]="item.MotivoDiferencia ? item.MotivoDiferenciaClave: ''" />
                  </td>
                  <td class="text-dark">
                    <span style="margin-left: 3px;"> {{item.MotivoDiferenciaNombre}}</span>
                  </td>

                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
          <div class="row">
            <!-- <div style="width:60%; padding-top: 10px;">

            </div> -->
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <table class="table table-borderless table-sm fw-bold m-0 mt-2" style="width:98%;">
                <tbody>
                  <tr>
                    <td class="pb-2" colspan="2"><b>Registros</b></td>
                    <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                      <b>{{this.totalRegistros|number:'1.2-6'}}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <hr class="m-0">
                    </td>
                  </tr>
                  <tr *ngIf="!getNoVerCostos">
                    <td class="pb-2" colspan="2"><b>Total</b></td>
                    <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                      <b>${{this.totalCosto|number:'1.2-6'}}</b>
                    </td>
                  </tr>
                  <tr *ngIf="!getNoVerCostos">
                    <td colspan="3">
                      <hr class="m-0">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <div class="row form-group">
            <div style="display: flex;padding-top: 5px;">
              <div style="width:10%">
                <label class="text-left height-label">Observaciones:</label>
              </div>
              <div style="width:90%;justify-content: flex-end;">
                <form [formGroup]="myFormTotales">
                  <components-ui-text-box label='Registros' formControlName="TotalRegistros" [tabIndex]="3"
                    [widthTextColumns]="2" [enabled]="false" [alinearDerecha]="true" [widthLabelColumns]="10"
                    [maxLength]="70" />
                </form>
              </div>
            </div>
          </div> -->



          <!-- <div class="row form-group">
            <div style="display: flex; justify-content: flex-start;width:75%;">
              <textarea maxlength="1000" formControlName="Observaciones" class="w-100" rows="2"
                (focus)="focoObservaciones($event)" cols="50"></textarea>
            </div>
          </div> -->
          <!-- </app-panel-base> -->
        </div>
      </app-panel-base>
      <app-panel-base title="Comentarios" [style]="'width:1195px;height:100px;'">
        <div class="panel-content">
          <textarea maxlength="1000" formControlName="Observaciones" class="w-100" rows="2"
            (focus)="focoObservaciones($event)" cols="50"></textarea>
        </div>
      </app-panel-base>
      <div style="margin-top:-350px;" class="cancelled-header animate__animated animate__bounceInDown"
        *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div class="cancelled-detail">{{getCancelDate}}</div>
        <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>
    </form>
  </div>

  <!-- <div   class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
    <div class="cancelled">&nbsp;</div>
    <div class="cancelled-detail">{{getCancelDate}}</div>
    <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
  </div> -->



  <ng-template #modalCancelarInvFisico let-modal let-c="close">
    <app-opcion-cancelacion-traspaso-page />
  </ng-template>

  <ng-template #ctrlConversionesInvFisico let-modal let-c="close">
    <app-conversiones-page [items]="conversiones" [unidadFinal]="unidadFinal" />
  </ng-template>


</app-container-base>
