import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { CuentasBancariasPageComponent } from './pages/cuentas-bancarias-page/cuentas-bancarias-page.component';
import { ConfigPrometeoPageComponent } from './pages/config-prometeo-page/config-prometeo-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgScrollbar } from 'ngx-scrollbar';
import { SaldosBancosPageComponent } from './pages/saldos-bancos-page/saldos-bancos-page.component';
import { BuscarMovimientosBancariosPageComponent } from './pages/buscar-movimientos-bancarios-page/buscar-movimientos-bancarios-page.component';
import { TipoTransferenciaPageComponent } from './pages/tipo-transferencia-page/tipo-transferencia-page.component';
import { TipoCajaChicaPageComponent } from './pages/tipo-caja-chica-page/tipo-caja-chica-page.component';
import { CajaChicaPageComponent } from './pages/caja-chica-page/caja-chica-page.component';
import { CtrlEdicionCajaComponent } from './pages/ctrl-edicion-caja/ctrl-edicion-caja.component';
import { ClaseGastosCajaChicaPageComponent } from './pages/clase-gastos-caja-chica-page/clase-gastos-caja-chica-page.component';
import { EfiscoModule } from "../efisco/efisco.module";
import { TiposGastosPageComponent } from './pages/tipos-gastos-page/tipos-gastos-page.component';
import { GastosPageComponent } from './pages/gastos-page/gastos-page.component';
import { ReporteCajaChicaPageComponent } from './pages/reportes/reporteCajaChicaPage/reporte-caja-chica-page.component';
import { VerificadorGastosPageComponent } from './pages/reportes/verificador-gastos-page/verificador-gastos-page.component';
import { BancosPageComponent } from './pages/bancos-page/bancos-page.component';
import { ConfigConciliacionPageComponent } from './pages/config-conciliacion-page/config-conciliacion-page.component';
import { EstadoCuentaConciliacionPageComponent } from './pages/estado-cuenta-conciliacion-page/estado-cuenta-conciliacion-page.component';
import { DiotGeneracionComponent } from './pages/diot-generacion/diot-generacion.component';
import { VerificadorDiotComponent } from './pages/verificador-diot/verificador-diot.component';
import { TerminalesBancariasPageComponent } from './pages/terminales-bancarias-page/terminales-bancarias-page.component';
import { AltaTerminalesBancariasPageComponent } from './pages/alta-terminales-bancarias-page/alta-terminales-bancarias-page.component';
import { TransferenciasPageComponent } from './pages/transferencias-page/transferencias-page.component';
import { PopupCancelarTransferenciaPageComponent } from './pages/popup-cancelar-transferencia-page/popup-cancelar-transferencia-page.component';
import { ChequesPageComponent } from './pages/cheques-page/cheques-page.component';
import { ContabilidadModule } from '../contabilidad/contabilidad.module';
import { VerificadorTransferenciasComponent } from './pages/verificador-transferencias/verificador-transferencias.component';
import { VerificadorChequesComponent } from './pages/verificador-cheques/verificador-cheques.component';
import { TiposValesPageComponent } from './pages/tipos-vales-page/tipos-vales-page.component';
import { PersonasValesPageComponent } from './pages/personas-vales-page/personas-vales-page.component';
import { CapturaValesPageComponent } from './pages/captura-vales-page/captura-vales-page.component';
import { OpcionCancelacionValePageComponent } from './pages/opcion-cancelacion-vale-page/opcion-cancelacion-vale-page.component';
import { ConciliacionManualPageComponent } from './pages/conciliacion-manual-page/conciliacion-manual-page.component';
import { VerificadorEstadosCuentasComponent } from './pages/verificador-estados-cuentas/verificador-estados-cuentas.component';
import { VerificadorValesComponent } from './pages/verificador-vales/verificador-vales.component';

@NgModule({
    exports: [],
    declarations: [
        CuentasBancariasPageComponent,
        ConfigPrometeoPageComponent,
        SaldosBancosPageComponent,
        BuscarMovimientosBancariosPageComponent,
        TipoTransferenciaPageComponent,
        TipoCajaChicaPageComponent,
        CajaChicaPageComponent,
        CtrlEdicionCajaComponent,
        ClaseGastosCajaChicaPageComponent,
        TiposGastosPageComponent,
        GastosPageComponent,
        ReporteCajaChicaPageComponent,
        VerificadorGastosPageComponent,
        BancosPageComponent,
        ConfigConciliacionPageComponent,
        EstadoCuentaConciliacionPageComponent,
        DiotGeneracionComponent,
        VerificadorDiotComponent,
        TerminalesBancariasPageComponent,
        AltaTerminalesBancariasPageComponent,
        TransferenciasPageComponent,
        PopupCancelarTransferenciaPageComponent,
        ChequesPageComponent,
        VerificadorTransferenciasComponent,
        VerificadorChequesComponent,
        TiposValesPageComponent,
        PersonasValesPageComponent,
        CapturaValesPageComponent,
        OpcionCancelacionValePageComponent,
        ConciliacionManualPageComponent,
        VerificadorEstadosCuentasComponent,
        VerificadorValesComponent
    ],
    providers: [],
    imports: [
        ComponentsModule,
        ContabilidadModule,
        CommonModule,
        FormsModule,
        ComponentUiModule,
        ReactiveFormsModule,
        DateBoxComponent,
        GuiGridModule,
        DragDropModule,
        NgScrollbar,
        EfiscoModule
    ]
})
export class FinanzasModule { }
