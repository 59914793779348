<app-container-base
  subTitle="Auditoría de CFDIS PPD, verifique que todos sus CFDI Recibidos PPD tengan todos sus Recibos Electrónicos de Pago"
  icon="fas fa-folder-tree" [hasCounter]="false" [hasBar]="false" idComponent="file-manager-component">
  <div class="screen-content" [style.height.px]="scrollHeihgt">
    <div class="file-manager h-100" [ngClass]="{ 'file-manager-sidebar-mobile-toggled': mobileSidebarToggled }">

      <div class="p-2">
        <form [formGroup]="myForm" autocomplete="off">
          <div class="row">
            <div class="col-2">
              <div class="row">
                <div class="col-6">
                  <input type="date" class="form-control" formControlName="fechaInicioString">
                </div>
                <div class="col-6">
                  <input type="date" class="form-control" formControlName="fechaFinString">
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="position-relative">
                <input type="text" class="form-control ps-35px rounded-3" formControlName="busqueda"
                  placeholder="Texto a buscar" #txtBuscarCfdi>
                <button class="btn position-absolute start-0 top-0 shadow-none"><i
                    class="fa fa-search fa-lg text-orange"></i></button>
              </div>
            </div>
            <div class="col-2">
              <a (click)="search()" class="btn btn-primary btn-rounded px-4 btn-sm d-block">Buscar</a>
            </div>
          </div>
        </form>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="btn-group me-2 m-2 w-200px">
            <div (click)="selTodos()" type="button" [class]="'btn btn-sm btn-white' + (!todos?'':' active')"><i
                [class]="'fa'+(!todos?'r':'')+' fa-fw fa-'+ (todos?'check':'square')+' ms-n1'"></i>Todos</div>
            <div (click)="selTodos()" type="button" [class]="'btn btn-sm btn-white' + (!ninguno?'':' active')">
              <i [class]="'fa'+(!ninguno?'r':'')+' fa-fw fa-'+ (ninguno?'check':'square')+' ms-n1'"></i>
              Ningúno
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="btn-group me-2 m-2">
            <button (click)="descargarZip()" [disabled]="getActivarOpciones" type="button"
              class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-download text-green"></i>
              Descargar .zip
            </button>
            <button (click)="validarEstado()" [disabled]="getActivarOpciones" type="button"
              class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-hamsa text-primary"></i>
              Validar Estado
            </button>

            <button (click)="imprimirSeleccionados()" [disabled]="getActivarOpciones" type="button"
              class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-file-pdf text-red"></i>
              Imprimir
            </button>
          </div>
        </div>
        <div class="col-2 text-end">
          <div class="fw-bold mt-20px">Registros: <b class="text-body">{{lista.length | number}}</b><b
              class="text-body"></b>
          </div>
        </div>
      </div>


      <div class="row ms-2">
        <div class="col-9">
          <table class="blueTable" style="width:100%;">
            <thead style="position: sticky;top:0;">
              <tr>
                <th style="width: 30px;">Sel</th>
                <th style="width: 30px;">PDF</th>
                <th style="width: 30px;">XML</th>
                <th style="width: 30px;">Vig</th>
                <th style="width: 30px;">MP</th>
                <th
                  [style]="'cursor: pointer;width:80px !important;'+((lastOrder=='tipoDeComprobante'?'background-color:orange;color:#ffffff;':''))"
                  (click)="ordenar('tipoDeComprobante')" class="px-10px">Tipo
                  <i *ngIf="lastOrder=='tipoDeComprobante'"
                    [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                </th>
                <th
                  [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='uuid'?'background-color:orange;color:#ffffff;':''))"
                  (click)="ordenar('uuid')" class="px-10px">UUID<i *ngIf="lastOrder=='uuid'"
                    [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                </th>
                <th
                  [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='emisorRfc'?'background-color:orange;color:#ffffff;':''))"
                  (click)="ordenar('emisorRfc')" class="px-10px">Emisor<i *ngIf="lastOrder=='emisorRfc'"
                    [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                </th>
                <th
                  [style]="'cursor: pointer;width:100px;'+((lastOrder=='serieFolio'?'background-color:orange;color:#ffffff;':''))"
                  (click)="ordenar('serieFolio')" class="px-10px text-center">Serie/Folio<i
                    *ngIf="lastOrder=='serieFolio'"
                    [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                </th>
                <th
                  [style]="'cursor: pointer;width:80px;'+((lastOrder=='fechaEmision'?'background-color:orange;color:#ffffff;':''))"
                  (click)="ordenar('fechaEmision')" class="px-10px text-center">Emisión<i
                    *ngIf="lastOrder=='fechaEmision'"
                    [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                </th>
                <th class="text-end"
                  [style]="'cursor: pointer;width:80px;'+((lastOrder=='total'?'background-color:orange;color:#ffffff;':''))"
                  (click)="ordenar('total')">Total Factura<i *ngIf="lastOrder=='total'"
                    [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                </th>
                <th class="text-end"
                  [style]="'cursor: pointer;width:80px;'+((lastOrder=='totalImporteReps'?'background-color:orange;color:#ffffff;':''))"
                  (click)="ordenar('totalImporteReps')">Importe REPS<i *ngIf="lastOrder=='totalImporteReps'"
                    [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                </th>
              </tr>
            </thead>
          </table>
          <cdk-virtual-scroll-viewport itemSize="20" style="height: 70vh;border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable" style="width:100%;">
              <tbody class="bg-light" style="font-family: math;">
                <tr
                  class="{{item.totalImporteReps > item.total ? 'bg-red-200': (item.totalImporteReps > 0 && item.totalImporteReps < item.total ? 'bg-green-200': '' )}}"
                  *cdkVirtualFor="let item of lista; let i = index;"
                  [style]="item.seleccionado ? 'background-color: #ebff01a1 !important;': ''">
                  <td class="text-center" style="width:30px !important;">
                    <input (click)="seleccionar(i)" class="form-check-input" type="checkbox"
                      [checked]="item.seleccionado" value="">
                  </td>
                  <td class="text-center" style="width:30px !important;"><i (click)="print(item.uuid)"
                      class="fa fa-file-pdf text-red fa-lg" style="cursor:pointer;"></i></td>
                  <td class="text-center" style="width:30px !important;"><i (click)="downloadXML(item.uuid)"
                      class="fa fa-file-code text-warning fa-lg" style="cursor:pointer;"></i></td>
                  <td class="text-center" style="width:30px;"> <i
                      [class]="'fas fa-lg fa-fw me-10px  '+(item.vigente?'fa-circle-check text-green':'fa-circle-xmark text-red')+' '"></i>
                  </td>
                  <td class="text-center" style="width:30px;"> {{ item.metodoDePago | uppercase }}</td>
                  <td class="text-center" style="width:80px;"> {{ item.tipoDeComprobante | uppercase }}</td>
                  <td style="width:200px;padding-left: 5px;">{{item.uuid | uppercase}}</td>

                  <td style="width:200px;padding-left: 5px;">{{item.emisorRfc}}/{{item.emisorNombre}}</td>
                  <td class="text-center" style="width:100px;padding-left: 5px;">{{item.serieFolio}}</td>
                  <td style="width:80px;" class="text-center">{{item.fechaEmision | date: 'dd/MM/yyy'}}</td>
                  <td style="width:80px;padding-right: 5px;" class="text-end">{{item.total| number:'1.2'}}</td>
                  <td style="width:73px;padding-right: 5px;" class="text-end btn-link cursor-pointer"
                    (click)="buscarReps(item)">{{item.totalImporteReps| number:'1.2'}}</td>
                </tr>
              </tbody>
            </table>
          </cdk-virtual-scroll-viewport>
        </div>
        <div class="col-3">
          <h5>Indicaciones</h5>
          <i class="fas fa-circle text-red-400"></i>
          Registros con Importe de REP mayor al total de la Factura
          <br>
          <i class="fas fa-circle text-green-400"></i>
          Registros con Importe de REP mayor a ceros o menor al total de la Factura
        </div>
      </div>
    </div>
  </div>
</app-container-base>


<ng-template #modalRepsRelacionados let-modal let-c="close">
  <div class="card text-center border-0" cdkDrag style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
    <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Documentos Relacionados</div>
    <div class="card body p-5px">
      <table class="blueTable tbl-reps-rels" style="width:100%;">
        <thead>
          <tr>
            <th style="width: 40px; text-align: center;">PDF</th>
            <th class="text-center" style="width: 245px">UUID Factura</th>
            <th class="text-center">SerieFolio</th>
            <th class="text-center">Moneda</th>
            <th class="text-end">Equivalencia</th>
            <th class="text-end">N° Parcial</th>
            <th class="text-end">Anterior</th>
            <th class="text-end">Pagado</th>
            <th class="text-end">Insoluto</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rep of repsRelacionados">
            <td class="text-center">
              <i (click)="print(rep.uuid)" class="fa fa-file-pdf text-red fa-lg" style="cursor:pointer;"></i>
            </td>
            <td class="text-center">{{rep.uuid}}</td>
            <td class="text-center">{{rep.serieRep}}{{rep.folioRep}}</td>
            <td class="text-center">{{rep.monedaDR}}</td>
            <td class="text-end">{{rep.equivalencia | number: '1.2-2'}}</td>
            <td class="text-end">{{rep.numParcialidad}}</td>
            <td class="text-end">{{rep.impSaldoAnterior | number: '1.2-2'}}</td>
            <td class="text-end">{{rep.impPagado | number: '1.2-2'}}</td>
            <td class="text-end">{{rep.impSaldoInsoluto | number: '1.2-2'}}</td>
          </tr>
          <tr *ngIf="repsRelacionados.length === 0">
            <td colspan="9" class="text-center">
              <h3 class="m-0">NO SE ENCONTRÓ NINGUN DOCUMENTO RELACIONADO</h3>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="text-align: right; margin-top: 5px;">
        <button (click)="closeModal()" class="btn btn-danger"> Cancelar</button>
      </div>
    </div>

  </div>
</ng-template>
