import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { EnviarMetaDataPageComponent } from './pages/enviar-meta-data-page/enviar-meta-data-page.component';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { SolicitudDescargaPageComponent } from './pages/solicitud-descarga-page/solicitud-descarga-page.component';
import { AlmacenCfdiPageComponent } from './pages/almacen-cfdi-page/almacen-cfdi-page.component';
import { DatePickerIconComponent } from '@generic-ui/fabric/common/icons/date-picker-icon/date-picker-icon.component';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { CtrlBuscadorCfdiComponent } from './pages/ctrl-buscador-cfdi/ctrl-buscador-cfdi.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AceptarRechazarPageComponent } from './pages/aceptar-rechazar-page/aceptar-rechazar-page.component';
import { PredefinidosProveedoresPageComponent } from './pages/predefinidos-proveedores-page/predefinidos-proveedores-page.component';
import { SeriesRetencionesPageComponent } from './pages/series-retenciones-page/series-retenciones-page.component';
import { ConstanciaPagosPageComponent } from './pages/constancia-pagos-page/constancia-pagos-page.component';
import { ConstanciaReceptoresPageComponent } from './pages/constancia-receptores-page/constancia-receptores-page.component';
import { VentasModule } from '../ventas/ventas.module';
import { OpcionCancelacionRetencionPageComponent } from './pages/opcion-cancelacion-retencion-page/opcion-cancelacion-retencion-page.component';
import { DescargaXmlCaptchaPageComponent } from './pages/descarga-xml-captcha-page/descarga-xml-captcha-page.component';
import { AdminCfdiPageComponent } from './pages/admin-cfdi-page/admin-cfdi-page.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AuditoriaCancelacionPageComponent } from './pages/auditoria-cancelacion-page/auditoria-cancelacion-page.component';
import { AuditoriaPpdPageComponent } from './pages/auditoria-ppd-page/auditoria-ppd-page.component';
import { AuditoriaRepsPageComponent } from './pages/auditoria-reps-page/auditoria-reps-page.component';

@NgModule({
  imports: [
    ScrollingModule,
    ComponentsModule,
    CommonModule,
    FormsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    DateBoxComponent,
    GuiGridModule,
    NgScrollbarModule,
    NgScrollbar,
    DragDropModule,
    VentasModule,
    NgxDatatableModule
  ],
  exports: [CtrlBuscadorCfdiComponent],
  declarations: [
    EnviarMetaDataPageComponent,
    SolicitudDescargaPageComponent,
    AlmacenCfdiPageComponent,
    CtrlBuscadorCfdiComponent,
    AceptarRechazarPageComponent,
    PredefinidosProveedoresPageComponent,
    SeriesRetencionesPageComponent,
    ConstanciaPagosPageComponent,
    ConstanciaReceptoresPageComponent,
    OpcionCancelacionRetencionPageComponent,
    DescargaXmlCaptchaPageComponent,
    AdminCfdiPageComponent,
    AuditoriaCancelacionPageComponent,
    AuditoriaPpdPageComponent,
    AuditoriaRepsPageComponent
  ],
  providers: [],
})
export class EfiscoModule { }
