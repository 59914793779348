<app-container-base subTitle="Catálogo de Clientes" (onClickBarButton)="clickBoton($event)" [isReport]="true"
  [activeButtons]="activeButtons" icon="far fa-file-pdf" [hasCounter]="false" [printManual]="true">
  <div class="screen-content">
    <form [formGroup]="frmPrintCatalogoClientes" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <components-ui-text-box label='Nombre del reporte' formControlName="NombreReporte" [tabIndex]="1"
            [widthLabelColumns]="2" [widthTextColumns]="8" [maxLength]="100" #txtNombreReporte />
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Filtros">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="2"
            (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.frmPrintCatalogoClientes"></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
