<app-container-base subTitle="Balance General Comparativo" (onClickBarButton)="clickBoton($event)" [isReport]="true"
  [activeButtons]="activeButtons" icon=" fas fa-scale-balanced" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <ng-template #loader>
            <screen-loading />
          </ng-template>
          <div class="row">
            <div class="col-md-8">
              <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
                [widthLabelColumns]="3" [widthTextColumns]="8" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Num1')" [selectManualItem]="getMes1"
                [setItemsList]="listaMeses" [listProperty]="'Nombre'" label="Período actual" [tabIndex]="2"
                [inputProperty]="'Nombre'" [widthLabelColumns]="6" [widthTextColumns]="4" #comboMes1 />
            </div>
            <div class="col-md-2">
              <component-ui-combo-box label='Año' [tabIndex]="3" [zeroMask]="2"
                [selectManualItem]="selectAnio1ManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="6"
                [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Num2')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" #comboAnio />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Num3')" [selectManualItem]="getMes2"
                [setItemsList]="listaMeses" [listProperty]="'Nombre'" label="Período comparación" [tabIndex]="2"
                [inputProperty]="'Nombre'" [widthLabelColumns]="6" [widthTextColumns]="4" #comboMes1 />
            </div>
            <div class="col-md-2">
              <component-ui-combo-box label='Año' [tabIndex]="3" [zeroMask]="2"
                [selectManualItem]="selectAnio2ManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="6"
                [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Num4')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" #comboAnio />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-check-box [label]="'Imprimir en Miles'" formControlName="Opc3" [tabIndex]="4"
                [widthTextColumns]="2" [widthLabelColumns]="7" />
            </div>
          </div>
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Generales">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="4"
            (FiltrosSeleccionChange)="changeselec($event)"></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
