<app-container-base subTitle="Verificador de Documentos de Inventario" (onClickBarButton)="imprimir($event)"
  [activeButtons]="activeButtons" [isReport]="true" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmVerificadorDocumentosInventario" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <components-ui-text-box [required]="true" label='Nombre del Reporte' formControlName="NombreReporte"
                [tabIndex]="1" [widthLabelColumns]="2" [widthTextColumns]="10" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' formControlName="Fecha1" [tabIndex]="2"
                [widthLabelColumns]="6" [widthTextColumns]="4" [required]="true"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' formControlName="Fecha2" [tabIndex]="3" [widthLabelColumns]="3"
                [widthTextColumns]="4" [required]="true"></components-ui-date-box>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box label='Agrupado' [tabIndex]="4" [zeroMask]="2" [setItemsList]="this.agrupados"
                [widthTextColumns]="4" [widthLabelColumns]="6" (onSelectedItem)="selectedCombo($event,'Num1')"
                inputProperty="ClaveNombre" listProperty="ClaveNombre" [selectManualItem]="selectAgrupadoItem"
                formControlName="Num1" helpLine="Ordenado de la información para la impresión del reporte." />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box label='Contenido' [tabIndex]="5" [zeroMask]="2" [setItemsList]="this.contenidos"
                [widthTextColumns]="4" [widthLabelColumns]="6" (onSelectedItem)="selectedCombo($event,'Num2')"
                inputProperty="ClaveNombre" listProperty="ClaveNombre" [selectManualItem]="selectContenidoItem"
                formControlName="Num2" helpLine="Ordenado de la información para la impresión del reporte." />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box label='Estado' [tabIndex]="6" [zeroMask]="2" [setItemsList]="this.estados"
                [widthTextColumns]="4" [widthLabelColumns]="6" (onSelectedItem)="selectedCombo($event,'Num3')"
                inputProperty="ClaveNombre" listProperty="ClaveNombre" [selectManualItem]="selectEstadoItem"
                formControlName="Num3" helpLine="Ordenado de la información para la impresión del reporte." />
            </div>
          </div>
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Filtros">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosVerificadorDocumentosInventario"
            [tabIndex]="7" (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.frmVerificadorDocumentosInventario"></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
