import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from 'src/app/auth/interfaces';
import { environment } from 'src/environments/environment';
import { EnvioEfisco } from '../interfaces/efisco.interface';

@Injectable({ providedIn: 'root' })

export class EfiscoService {
  private readonly baseUrl: string = environment.baseUrlApi;
  constructor(private http: HttpClient) {

  }

  descargarCaptcha(idEmpresa: number, fechaInicio: string, fechaFin: string, rfc: string, uuid: string, recibidos: boolean, tipoComp: string){
    const params = new HttpParams()
                    .set("idEmpresa", idEmpresa)
                    .set("fechaInicio", fechaInicio)
                    .set("fechafin", fechaFin)
                    .set("rfc", rfc)
                    .set("uuid", uuid)
                    .set("recibidos", recibidos)
                    .set("tipoComp", tipoComp);
    return this.http.get<Result>(`${this.baseUrl}/Fiscal/DescargarXmlsConCaptcha`, { params });
  }

  verify(){
    return this.http.get<EnvioEfisco[]>(`${this.baseUrl}/Ventas/VerificarEstatusEnviosEfisco`);
  }

  getHistory(){
    return this.http.get<EnvioEfisco[]>(`${this.baseUrl}/Ventas/ObtenerEnviosEfisco`);
  }

  sendCfdiToEfisco(idEmpresa: number, idTipoComp: number, fechaInicio: string, fechaFin:string) {
    const params = new HttpParams()
                    .set("idEmpresa", idEmpresa)
                    .set("idTipoComp", idTipoComp)
                    .set("fechaInicio", fechaInicio)
                    .set("fechaFin", fechaFin);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EnviarCfdiEfisco`, { params });
  }

  getListCfdis(){
    return this.http.get<any[]>(`${this.baseUrl}/Fiscal/ObtenerListadoCFDIs`);
  }

  getRepsCFDIs(uuid:any){
    const params = new HttpParams().set("uuid", uuid);
    return this.http.get<any>(`${this.baseUrl}/Fiscal/BuscarRepsRelacionados`, { params });
  }
}
