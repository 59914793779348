import { Injectable, inject } from "@angular/core";
import { ReportFilter } from "../component-ui/interfaces/selection-filter.interface";
import { Observable, Subscription } from 'rxjs';
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { EventsService } from "./events.service";
import { UtilsService } from "./utils.service";
import { Result } from "../auth/interfaces";
import { ComboBoxEntity } from "../component-ui/interfaces/combo-text.interface";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private eventsService = inject(EventsService);
  private utilsService = inject(UtilsService);
  private readonly baseUrl: string = environment.baseUrlApi;

  constructor(private http: HttpClient, private router: Router) {
  }


  printReport(reportFilter: ReportFilter, url: string, exportar: boolean = false, callback: any = null) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<any>(`${this.baseUrl}/Config/RegistrarActividadReportes`, reportFilter).subscribe(result => { });
    reportFilter.NombreEmpresa = this.utilsService.getUserLogged().empresa?.nombre!;
    console.log(reportFilter);
    this.http.post<any>(`${this.baseUrl}${url}`, reportFilter).subscribe({
      next: result => {
        this.eventsService.publish('home:isLoading', { isLoading: false });

        if (result.success) {
          let res: number = result.message.indexOf("<mensaje>");
          let mensaje: string = "";
          if (res != -1) {
            mensaje = result.message.substring(res);
            result.message = result.message.substring(0, res);
          }


          if (result.message === "") {
            this.eventsService.publish('home:showAlert', { message: `No se encontró información a imprimir.`, cancelButton: false });
            return;
          }

          if (exportar) {
            let link = document.createElement('a');
            link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(result.message);
            link.setAttribute('download', reportFilter.NombreExcel);
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            callback && callback();
            this.createBlobForPrint(result.message)
            const url1 = this.router.serializeUrl(this.router.createUrlTree(['/reportes'], {
              queryParams: {
                //base64: urlBlobPdf,
                entity: "",
                title: reportFilter.TituloVisor,
                excelFileName: reportFilter.NombreExcel,
                reportFilter: JSON.stringify(reportFilter),
                urlReporte: url
              }
            }));
            window.open(url1, '_blank');
          }
        } else {
          this.errorReport({ error: result })
        }
      }, error: err => {
        console.log(err);
        this.errorReport(err)
      }
    })
  }

  printReportWithoutFilters(url: string, nombre: string = "", exportar: boolean = false, callback: any = null) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<any>(`${this.baseUrl}${url}`, {}).subscribe({
      next: result => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        callback && callback();
        if (result.success) {
          if (result.message === "") {
            this.eventsService.publish('home:showAlert', { message: "No se encontró información a imprimir.", cancelButton: false });
            return;
          }

          if (exportar) {
            let link = document.createElement('a');
            link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(result.message);
            link.setAttribute('download', nombre);
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            this.createBlobForPrint(result.message);
            const url1 = this.router.serializeUrl(this.router.createUrlTree(['/reportes'], {
              queryParams: {
                // base64: result.message,
                entity: "",
                title: nombre,
                excelFileName: nombre,
                urlReporte: url
              }
            }));
            window.open(url1, '_blank');
          }
        } else {
          this.errorReport({ error: result })
        }
      }, error: err => this.errorReport(err)
    })
  }

  printReportWithBody(body: any, url: string, nombre: string = "", exportar: boolean = false, callback: any = null) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<any>(`${this.baseUrl}${url}`, body).subscribe({
      next: result => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        callback && callback();
        if (result.success) {
          if (result.message === "") {
            this.eventsService.publish('home:showAlert', { message: "No se encontró información a imprimir.", cancelButton: false });
            return;
          }

          if (exportar) {
            let link = document.createElement('a');
            link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(result.message);
            link.setAttribute('download', nombre);
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            this.createBlobForPrint(result.message);
            const url1 = this.router.serializeUrl(this.router.createUrlTree(['/reportes'], {
              queryParams: {
                // base64: result.message,
                entity: "",
                title: nombre,
                excelFileName: nombre,
                urlReporte: url
              }
            }));
            window.open(url1, '_blank');
          }
        } else {
          this.errorReport({ error: result })
        }
      },
      error: err => this.errorReport(err)
    })
  }

  errorReport(err: any) {
    console.error("Error al generar el reporte: ", err.error);
    this.eventsService.publish('home:isLoading', { isLoading: false });
    this.eventsService.publish('home:showAlert', { message: "Error al imprimir el reporte:," + err.error, icon: 'fa-bug text-red', cancelButton: false });
  }

  private createBlobForPrint(pdfB64: string) {
    let pdfBlob = this.utilsService.base64ToBlob(pdfB64, 'application/pdf');
    let urlBlobPdf = window.URL.createObjectURL(pdfBlob);
    localStorage.setItem("pdfblob", urlBlobPdf);
  }


  printReportFromBase64String(reportFilter: ReportFilter, url: string, exportar: boolean = false, archivo: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<any>(`${this.baseUrl}/Config/RegistrarActividadReportes`, reportFilter).subscribe(result => { });
    this.eventsService.publish('home:isLoading', { isLoading: false });
    if (exportar) {
      let link = document.createElement('a');
      link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(archivo);
      link.setAttribute('download', reportFilter.NombreExcel);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      this.createBlobForPrint(archivo)
      const url1 = this.router.serializeUrl(this.router.createUrlTree(['/reportes'], {
        queryParams: {

          entity: "",
          title: reportFilter.TituloVisor,
          excelFileName: reportFilter.NombreExcel,
          reportFilter: JSON.stringify(reportFilter),
          urlReporte: url
        }
      }));
      window.open(url1, '_blank');
    }
  }

  getUserFilter(reportFilter: ReportFilter) {
    return this.http.post<any>(`${this.baseUrl}/Config/ObtenerUsuarioFiltros`, reportFilter);

  }

  setUserFilter(reportFilter: ReportFilter) {
    return this.http.post<any>(`${this.baseUrl}/Config/GuardarUsuarioFiltros`, reportFilter);
  }


  getUserFilterList(menuId:string) {
    const params = new HttpParams().set("menuId", menuId);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Config/ObtenerListadoUsuarioFiltros`, { params });
  }


  eliminarUserFilter(menuId:string){
    const params = new HttpParams().set("menuId", menuId);
    return this.http.get<Result[]>(`${this.baseUrl}/Config/EliminarUsuarioFilter`, { params });
  }


}
