<app-container-base subTitle="Verificador de Resguardos Surtidos/Por Surtir" (onClickBarButton)="clickBoton($event)"
  [isReport]="true" [activeButtons]="activeButtons" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <components-ui-text-box label='Nombre del Reporte' [tabIndex]="1" [widthLabelColumns]="2"
              [widthTextColumns]="8" [maxLength]="100" formControlName="NombreReporte"
              helpLine="Nombre para el encabezado del reporte impreso." #txtNombreReporte />
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha de emisión   Inicio' [widthLabelColumns]="6" [widthTextColumns]="3"
                [tabIndex]="2" formControlName="Fecha1" [required]="true"
                [enabled]="this.opcionSeleccionada! && this.opcionSeleccionada!.Id != 2"
                (onChangeValue)="onChangeFecha($event)"
                helpLine="Fecha de emisión inicio para generar el reporte."></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fin' [widthLabelColumns]="3" [widthTextColumns]="3" [tabIndex]="3"
                formControlName="Fecha2" [required]="true"
                [enabled]="this.opcionSeleccionada! && this.opcionSeleccionada!.Id != 2"
                (onChangeValue)="onChangeFecha($event)"
                helpLine="Fecha de emisión fin para consultar el reporte."></components-ui-date-box>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box label='Estado' [tabIndex]="3" [zeroMask]="2"
                [selectManualItem]="selectOpcionManualItem" [setItemsList]="this.listaOpciones" [widthTextColumns]="6"
                [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Num1')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" #comboAnio />
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Al' [widthLabelColumns]="3" [widthTextColumns]="3" [tabIndex]="3"
                formControlName="FechaCorte1" [enabled]="this.opcionSeleccionada! && this.opcionSeleccionada!.Id == 2"
                (onChangeValue)="onChangeFechaFechaCorte($event)"
                helpLine="Fecha de corte para obtener el reporte surtido y por surtir."></components-ui-date-box>
            </div>
          </div>
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Generales">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="8"
            (FiltrosSeleccionChange)="changeselec($event)" [ReportHeader]="this.myForm"
            (ReportHeaderChange)="reportHeaderChange($event) "></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
