<div class="card text-center border-0"
  style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;position: absolute; z-index:9;margin-left:150px;margin-top:-250px;width:500px;"
  cdkDrag>
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">{{this.esAltaPredefinido?  'Nuevo Predefinido':'Seleccionar Predefinido'}}
  </div>
  <app-panel-base title="{{this.esAltaPredefinido? 'Nuevo Predefinido':'Seleccionar Predefinido'}}">
    <div class="panel-content">
      <form [formGroup]="myForm" autocomplete="off">
        <div style="margin-bottom: 10px;" *ngIf="this.esAltaPredefinido">
          <components-ui-text-box formControlName="Nombre" [maxlength]="2000" label="Nombre" [widthLabelColumns]="3"
            [widthTextColumns]="9" />
        </div>

        <ng-scrollbar style="height: 180px; width:500px; border-bottom: 1px solid #AAAAAA;">
          <table class="blueTable" style="width:490px;">
            <thead style="position: sticky;top:0;">
              <tr>
                <th style="width: 50px;" *ngIf="this.esAltaPredefinido">Eliminar</th>
                <th style="width: 400px;">Nombre</th>
              </tr>
            </thead>
            <tbody class="bg-light">
              <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                *ngFor="let item of sourceGrid; let index = index; "  (dblclick)="seleccionarPredefinido(item)" >
                <td style="text-align: center;" *ngIf="this.esAltaPredefinido">
                  <i (click)="deleteRow(item)" class="fa fa-rectangle-xmark fa-1x"
                    style="color:red;font-weight: bold; cursor: pointer;"></i>
                </td>
                <td class="text-dark" (click)="clicEnLista(item)">
                  <span (click)="clicEnLista(item)" style="margin-left: 3px;"> {{item.nombre}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-scrollbar>


        <div style="text-align: right; margin-top: 5px;">
          <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
          <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
        </div>
      </form>
    </div>
  </app-panel-base>
</div>
