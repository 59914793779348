import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { NumberBoxComponent } from './components/number-box/number-box.component';
import { PasswordBoxComponent } from './components/password-box/password-box.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { ContainerBaseComponent } from './components/container-base/container-base.component';
import { PanelBaseComponent } from './components/panel-base/panel-base.component';
import { TimeBoxComponent } from './components/time-box/time-box.component';
import { ScreenLoadingComponent } from './components/screen-loading/screen-loading.component';
import { BarButtonComponent } from './components/bar-button/bar-button.component';
import { FileUploadBoxComponent } from './components/file-upload-box/file-upload-box.component';
import { AddressComponent } from './components/address/address.component';
import { BarBaseComponent } from './components/bar-base/bar-base.component';
import { ListBoxComponent } from './components/list-box/list-box.component';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from '@swimlane/ngx-charts';
import { DataSearchComponent } from './components/data-search/data-search.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CheckBoxListComponent } from './components/check-box-list/check-box-list.component';
import { SelectionFilterComponent } from './components/selection/selectionFilter.component';
import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LevelTextBoxComponent } from './components/level-text-box/level-text-box.component';
import { SeleccionarTreeComponent } from './components/seleccionar-familia-page/seleccionar-familia-page.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorsPercentComponent } from './components/colors-percent/colors-percent.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AlertComponent } from './components/alert/alert.component';
import { PadStartPipe } from './pipes/pad-start.pipe';
import { MatRippleModule } from '@angular/material/core';
import { DatagridComponent } from './components/datagrid/datagrid.component';
import { CtrlAsientoContableComponent } from './components/ctrl-asiento-contable/ctrl-asiento-contable.component';
import { CtrlAsientoContableEdicionComponent } from './components/ctrl-asiento-contable-edicion/ctrl-asiento-contable-edicion.component';
import { SelectionFilterNuevoPredefinidoComponent } from './components/selection-filter-nuevo-predefinido/selection-filter-nuevo-predefinido.component';

//NgbTooltipModule
// TooltipModule


@NgModule({
  declarations: [
    TextBoxComponent,
    NumberBoxComponent,
    PasswordBoxComponent,
    CheckBoxComponent,
    ComboBoxComponent,
    ContainerBaseComponent,
    PanelBaseComponent,
    TimeBoxComponent,
    ScreenLoadingComponent,
    BarButtonComponent,
    FileUploadBoxComponent,
    ListBoxComponent,
    AddressComponent,
    BarBaseComponent,
    DataSearchComponent,
    CheckBoxListComponent,
    SelectionFilterComponent,
    SelectionFilterComponent,
    TreeViewComponent,
    LevelTextBoxComponent,
    SeleccionarTreeComponent,
    ColorPickerComponent,
    ColorsPercentComponent,
    DropDownComponent,
    AlertComponent,
    PadStartPipe,
    DatagridComponent,
    CtrlAsientoContableComponent,
    CtrlAsientoContableEdicionComponent,
    SelectionFilterNuevoPredefinidoComponent
  ],
  exports: [
    TextBoxComponent,
    NumberBoxComponent,
    PasswordBoxComponent,
    CheckBoxComponent,
    ComboBoxComponent,
    ContainerBaseComponent,
    PanelBaseComponent,
    TimeBoxComponent,
    ScreenLoadingComponent,
    BarButtonComponent,
    FileUploadBoxComponent,
    ListBoxComponent,
    AddressComponent,
    BarBaseComponent,
    DataSearchComponent,
    CheckBoxListComponent,
    SelectionFilterComponent,
    TreeViewComponent,
    LevelTextBoxComponent,
    SeleccionarTreeComponent,
    ColorPickerComponent,
    DropDownComponent,
    AlertComponent,DatagridComponent, CtrlAsientoContableComponent],
  imports: [
    TooltipModule,
    NgbTooltipModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgScrollbarModule,
    ColorChromeModule,
    NgbDropdownModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DragDropModule,
    MatRippleModule,
  ]
})
export class ComponentUiModule { }
