import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-verificador-vales',
  templateUrl: './verificador-vales.component.html',
  styleUrls: ['./verificador-vales.component.scss']
})
export class VerificadorValesComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
  menuId: string = "verificadorvales";
  private reportsService = inject(ReportsService);
  private frmBuilder = inject(FormBuilder);




  // onBlur() {
  //   this.tagInput.nativeElement.classList.remove("pulse-text");
  // }
  // onFocus() {
  //   this.tagInput.nativeElement.classList.add("pulse-text");
  //   if (this.helpLine) {
  //     let pes = document.querySelectorAll('.lineadeayuda');
  //     pes.forEach((elem: any) => { elem.innerHTML = this.helpLine ? this.helpLine : ""; });
  //   }
  // }


  ngOnInit(): void {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus();
      //this.ordenadoSeleccionado = this.listaOrdenado[0];
      this.agrupadoSeleccionado = this.listaAgrupado[0];
    }, 300);
  }

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  public frmVerificadorTransferencias: FormGroup = this.frmBuilder.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: ['Verificador de Vales', Validators.required],
    Num1: [1],
    Num2: [1],
    Cantidad: [0],
    Opc1: [false],
    Opc2: [false],
    Opc3: [true],
  });


  // listaOrdenado: any[] = [
  //   { Id: 1, Nombre: "Fecha Vale" },
  //   { Id: 2, Nombre: "Importe De Menor A Mayor" },
  //   { Id: 3, Nombre: "Importe De Mayor A Menor" },
  // ];


  listaAgrupado: any[] = [
    { Id: 1, Nombre: "Sucursal" },
    { Id: 2, Nombre: "Sucursal - Departamento" },
    { Id: 3, Nombre: "Sucursal - Departamento - Tipo Vale" },
    { Id: 4, Nombre: "Departamento" },
    { Id: 5, Nombre: "Tipo Vale" },
    { Id: 6, Nombre: "Ninguno" },
  ];

  ordenadoSeleccionado?: ComboBoxEntity;
  get selectOrdenadoItem(): ComboBoxEntity | null {
    return this.ordenadoSeleccionado!;
  }

  agrupadoSeleccionado?: ComboBoxEntity;
  get selectAgrupadoItem(): ComboBoxEntity | null {
    return this.agrupadoSeleccionado!;
  }


  orderByGroupBy: string = "s.Clave,";

  selectedCombo(entity: ComboBoxEntity, field: string) {
    this.frmVerificadorTransferencias.controls[field].setValue(entity.Id);

    if (field === "Num1") {
      switch (entity.Id) {
        case 1: //Sucursal
          this.orderByGroupBy = "s.Clave,";
          break;
        case 2: //Sucursal - Departamento
          this.orderByGroupBy = "s.Clave, d.Clave,";
          break;
        case 3: //Sucursal - Departamento - Tipo Vale
          this.orderByGroupBy = "s.Clave, d.Clave, tv.Clave,";
          break;
        case 4: //Departamento
          this.orderByGroupBy = "d.Clave,";
          break;
        case 5: //Tipo Vale
          this.orderByGroupBy = "tv.Clave,";
          break;
        default:
          this.orderByGroupBy = "";
          break;
      }
    }
  }

  public ordenadoLista: ReportFilterOrderList[] = [
    { Id: 1, Nombre: "Fecha Vale", Orden: "v.FechaEmision" },
    { Id: 2, Nombre: "Importe De Menor A Mayor", Orden: "v.Total asc" },
    { Id: 3, Nombre: "Importe De Mayor A Menor", Orden: "v.Total desc" },
  ]

  public filtrosVerificadorVales: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Kardex'
      } as ReportHeader,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Verificador de Vales',
      TituloVisor: 'Verificador de Vales',
      NombreExcel: 'VerificadorVales.xlsx',
      OrdenadoLista: this.ordenadoLista,
      FilterOptions: [
        { Campo: 'e.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 's.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'tv.Clave', Etiqueta: 'Tipo de Vale', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "TipoVale" },
        { Campo: 'pv.Clave', Etiqueta: 'Recibe', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ValePersona" },
        { Campo: 'ua.Clave', Etiqueta: 'Autoriza', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'ue.Clave', Etiqueta: 'Elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
      ]
    };


  imprimir(event: any) {
    this.filtrosVerificadorVales.ReportHeader = this.frmVerificadorTransferencias.value;
    let filtrosValesClone = _.cloneDeep(this.filtrosVerificadorVales);
    filtrosValesClone.Ordenado = this.orderByGroupBy + filtrosValesClone.Ordenado;
    // filtrosValesClone.FilterOptions?.push({ Campo: 'v.Total', Etiqueta: "", Tipo: TypeFilter.number, Valor: this.frmVerificadorTransferencias.controls["Importe"].value },);
    this.reportsService.printReport(filtrosValesClone, '/Tesoreria/ReporteVerificadorVales');
  }

  changeselec(event: any) {
    this.filtrosVerificadorVales = event;
  }

  onClickCheckImporte(event: any, opc: string) {
    switch (opc) {
      case "Opc1":
        this.frmVerificadorTransferencias.controls[opc].setValue(event.target.value);
        this.frmVerificadorTransferencias.controls["Opc2"].setValue(false);
        this.frmVerificadorTransferencias.controls["Opc3"].setValue(false);
        break;
      case "Opc2":
        this.frmVerificadorTransferencias.controls[opc].setValue(event.target.value);
        this.frmVerificadorTransferencias.controls["Opc1"].setValue(false);
        this.frmVerificadorTransferencias.controls["Opc3"].setValue(false);
        break;
      case "Opc3":
        this.frmVerificadorTransferencias.controls[opc].setValue(event.target.value);
        this.frmVerificadorTransferencias.controls["Opc1"].setValue(false);
        this.frmVerificadorTransferencias.controls["Opc2"].setValue(false);
        break;
    }
  }
}
