
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { environment } from 'src/environments/environment';
import { AlertResponse } from '../../interfaces/alert.interface';
import { Result } from 'src/app/auth/interfaces';

@Component({
  selector: 'selection-filter-nuevo-predefinido',
  templateUrl: './selection-filter-nuevo-predefinido.component.html',
})
export class SelectionFilterNuevoPredefinidoComponent {

  private eventsService = inject(EventsService);
  private readonly baseUrl: string = environment.baseUrlApi;
  private http = inject(HttpClient);
  sourceGrid: Array<any> = [];
  indexEditing = -1;
  itemSeleccionado: any | null = null;

  @Input()
  public menuId: string | null = null;

  @Input()
  public esAltaPredefinido: boolean = false;

  public myForm: FormGroup = this.fb.group({
    Nombre: ['', Validators.required],
    Id: [0]
  })

  constructor(private fb: FormBuilder,
    private mService: ModalService) {
  }

  accept() {
    if (this.myForm.value.Nombre == "") {
      this.eventsService.publish('home:showAlert', { message: "Debe especificar un nombre para el predefinido, por favior verifique", cancelButton: false });
      return;
    }
    this.mService.closeModal(this.myForm.value);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  deleteRow(item: any) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el Predefinido?.',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          const params = new HttpParams().set("Id", item.id);
          this.http.get<Result[]>(`${this.baseUrl}/Config/EliminarUsuarioFilter`, { params }).subscribe(result => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            this.mService.closeModal(null);
          });
        }
      }
    });
  }

  seleccionarPredefinido(item: any) {
    if (!this.esAltaPredefinido) {
      this.mService.closeModal(this.myForm.value);
    }
  }

  clicEnLista(item: any) {
    this.itemSeleccionado = item;
    this.myForm.get('Nombre')?.setValue(this.itemSeleccionado?.nombre);
    this.myForm.get('Id')?.setValue(this.itemSeleccionado?.id);
  }

  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("menuId", this.menuId!);
    this.http.get<any[]>(`${this.baseUrl}/Config/ObtenerListadoUsuarioFiltros`, { params }).subscribe(result => {
      this.sourceGrid = result;
      this.eventsService.publish('home:isLoading', { isLoading: false });
    });
  }
}
