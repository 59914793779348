import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'verificador-resguardos-por-surtido-por-surtir',
  templateUrl: './verificador-resguardos-por-surtido-por-surtir.component.html',
})
export class VerificadorResguardosPorSurtidoPorSurtirComponent implements OnInit, AfterViewInit {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private _reportsService = inject(ReportsService);
  public get reportsService() {
    return this._reportsService;
  }
  public set reportsService(value) {
    this._reportsService = value;
  }

  private utils = inject(UtilsService);

  menuId: string = "resguardospendienstesporsurtir";
  opcionSeleccionada?: ComboBoxEntity;
  listaOpciones: any[] = [];


  public filtrosReporte: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      menuId: this.menuId,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Verificador de Resguardos surtidos-porsurtir',
      NombreExcel: 'Verificador de Resguardos surtidos-porsurtir.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'res.SerieFactura', Etiqueta: 'Serie Factura', Tipo: TypeFilter.text },
        { Campo: 'res.FolioFactura', Etiqueta: 'Folio factura', Tipo: TypeFilter.number },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'vendedor.Clave', Etiqueta: 'Vendedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario",Filtro: "EsVendedor = true" },
        { Campo: 'pro.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Producto" },
        { Campo: 'fam.ClaveGeneral', Etiqueta: 'Familias', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily, HelpLine:"Familias a de las que se buscarán los registros."  },
      ]
    };
///ss

  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    FechaCorte1: null,
    // FechaCorte2: new Date(),
    NombreReporte: '',
    Num1: 0,
    Opc1: false
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder) { }
  ngAfterViewInit(): void {

    this.listaOpciones = [{ Id: 0, Clave: 0, Nombre: "Todos" },
    { Id: 1, Clave: 1, Nombre: "Surtido" },
    { Id: 2, Clave: 2, Nombre: "Sin Surtir" },]

    this.opcionSeleccionada = this.listaOpciones[0];

  }

  reportHeaderChange(e: any) {
    this.opcionSeleccionada = this.listaOpciones.find(p => p.Id == e.Num1);

    //console.log(e.Num1);

  }


  ngOnInit(): void {
    const fecha: string = this.utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE RESGUARDOS SURTIDOS/PORSURTIR ' + this.rangoFechas);
    this.focus('txtNombreReporte');
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Num1') {
      this.opcionSeleccionada = entity;
      if (entity.Id == 2) {
        this.myForm.get("FechaCorte1")?.setValue(new Date());
      } else {
        this.myForm.get("FechaCorte1")?.setValue(null);
      }
    }
    this.myForm.controls[field].setValue(entity.Clave);
  }


  get selectOpcionManualItem(): ComboBoxEntity | null {

    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE RESGUARDOS SURTIDOS/PORSURTIR ' + this.rangoFechas);
    return this.opcionSeleccionada!;
  }


  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE RESGUARDOS SURTIDOS/PORSURTIR ' + this.rangoFechas);
  }

  onChangeFechaFechaCorte(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE RESGUARDOS SURTIDOS/PORSURTIR ' + this.rangoFechas);
  }


  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }

  clickBoton(event: string) {

    let fechaFin: Date | null = null;
    if (this.opcionSeleccionada?.Id == 2) {
      fechaFin = this.myForm.value.FechaCorte1;
    } else {
      fechaFin = this.myForm.value.Fecha2;
      this.myForm.get("FechaCorte1")?.setValue(null);
    }


    if (fechaFin?.getDate() == new Date().getDate()) {
      this.myForm.value.Opc1 = true;
    } else {
      this.myForm.value.Opc1 = false;
    }



    const ReporteDiarioDeVentas = this.myForm.value;
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Resguardo/VerificadorResguardoSurtidoPorSurtir');
  }



}
