<app-container-base subTitle="Verificador de Vales" (onClickBarButton)="imprimir($event)" [isReport]="true"
  [activeButtons]="activeButtons" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmVerificadorTransferencias" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <components-ui-text-box [required]="true" label='Nombre del Reporte' formControlName="NombreReporte"
                [tabIndex]="1" [widthLabelColumns]="2" [widthTextColumns]="10" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' formControlName="Fecha1" [tabIndex]="2"
                [widthLabelColumns]="6" [widthTextColumns]="4" [required]="true"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' formControlName="Fecha2" [tabIndex]="3" [widthLabelColumns]="3"
                [widthTextColumns]="4" [required]="true"></components-ui-date-box>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box label='Agrupado Por' [tabIndex]="4" [zeroMask]="2"
                [selectManualItem]="selectAgrupadoItem" [setItemsList]="this.listaAgrupado" [widthTextColumns]="6"
                [widthLabelColumns]="6" (onSelectedItem)="selectedCombo($event,'Num1')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <components-ui-number-box label='Importe' formControlName="Cantidad" [tabIndex]="7" [widthLabelColumns]="6"
                [widthTextColumns]="6" [maxLength]="100" #txtNombreReporte />
            </div>
            <div class="col-md-4 d-flex">
              <div class="form-check me-30px">
                <input class="form-check-input" formControlName="Opc3" (click)="onClickCheckImporte($event, 'Opc3')"
                  type="checkbox" id="mayor-igual" />
                <label class="form-check-label" for="mayor-igual">
                  Mayor Igual
                </label>
              </div>
              <div class="form-check me-30px">
                <input class="form-check-input" formControlName="Opc1" (click)="onClickCheckImporte($event, 'Opc1')"
                  type="checkbox" id="menor-igual" />
                <label class="form-check-label" for="menor-igual">
                  Menor Igual
                </label>
              </div>
              <div class="form-check me-30px">
                <input class="form-check-input" formControlName="Opc2" (click)="onClickCheckImporte($event, 'Opc2')"
                  type="checkbox" id="igual" />
                <label class="form-check-label" for="igual">
                  Igual
                </label>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosVerificadorVales" [tabIndex]="11"
        (FiltrosSeleccionChange)="changeselec($event)"
        [ReportHeader]="this.frmVerificadorTransferencias"></component-ui-selection-filter>
    </form>
  </div>
</app-container-base>
