<app-container-base subTitle="Impresión para conteo" (onClickBarButton)="eventosBotones($event)" [isReport]="true"
  [activeButtons]="activeButtons" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmConteoInventario" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <components-ui-text-box helpLine="Nombre del encabezado del reporte impreso." [required]="true"
                label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1" [widthLabelColumns]="2"
                [widthTextColumns]="10" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <component-ui-check-box helpLine="Seleccionar si solo se necesitan registros con Existencia."
                label='Solo con Existencia' formControlName="Opc1" [tabIndex]="2" [widthLabelColumns]="2"
                [widthTextColumns]="3" (onClick)="onClickCheckBox('opc1',$event)" />
              <component-ui-check-box helpLine="Mostrar la columna Existencia en el reporte impreso."
                label='Imprimir Existencia' formControlName="Opc2" [tabIndex]="3" [widthLabelColumns]="2"
                [widthTextColumns]="3" (onClick)="onClickCheckBox('opc2', $event)" />
            </div>
          </div>
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Filtros">
        <div class="panel-content"> -->
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosConteoInventario" [tabIndex]="4"
            [ReportHeader]="this.frmConteoInventario"
            (FiltrosSeleccionChange)="changeselec($event)"></component-ui-selection-filter>
        <!-- </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
