import { Component, ElementRef, HostListener, ViewChild, inject } from '@angular/core';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { EfiscoService } from '../../services/efisco.service';
import { error } from 'jquery';
import { DescargaCfdi } from '../../interfaces/solicitud.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { EventsService } from 'src/app/service/events.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { addDays } from 'date-fns';
import { Result } from 'src/app/auth/interfaces';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import Swal from 'sweetalert2';
import { orderBy } from 'lodash';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { TipoPoliza } from 'src/app/contabilidad/interfaces/tipopoliza.interface';
import { CfdiEstadoSAT } from 'src/app/ventas/interfaces/venta.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { ReportFilter, ReportHeader } from 'src/app/component-ui/interfaces/selection-filter.interface';

@Component({
  selector: 'app-admin-cfdi-page',
  templateUrl: './admin-cfdi-page.component.html',
  styleUrls: ['./admin-cfdi-page.component.scss']
})
export class AdminCfdiPageComponent {
  private readonly baseUrl: string = environment.baseUrlApi;
  columns = [{ name: 'Sel' }, { name: 'PDF' }, { name: 'XML' }, { name: 'Tipo' }, { name: 'UUID' }, { name: 'Emisor' }, { name: 'Receptor' }, { name: 'EmisSerie/Folioor' }, { name: 'Fecha' }, { name: 'Total' }];
  ColumnMode = ColumnMode;
  lista: DescargaCfdi[] = []
  efiscoService = inject(EfiscoService);
  eventsService = inject(EventsService);
  mobileSidebarToggled: boolean = false;
  recibidos: boolean = true;
  emitidos: boolean = false;

  ordenados = [
    { name: 'tipoDeComprobante', order: 'desc' },
    { name: 'uuid', order: 'desc' },
    { name: 'emisorRfc', order: 'desc' },
    { name: 'receptorRfc', order: 'desc' },
    { name: 'serieFolio', order: 'desc' },
    { name: 'fechaEmision', order: 'desc' },
    { name: 'total', order: 'desc' },
    { name: 'subtotal', order: 'desc' },
    { name: 'ivaTrasladado', order: 'desc' },
    { name: 'ivaRetenido', order: 'desc' },
    { name: 'isrRetenido', order: 'desc' },
    { name: 'receptorUsoCfdi', order: 'desc' },
    { name: 'totalTrasladosImpuestoIVA16', order: 'desc' },
  ];

  lastOrder: string = "";
  lastOrderAsc: string = "";
  todos: boolean = false;
  ninguno: boolean = true;
  tiposPoliza: any[] = [];
  @ViewChild('txtBuscarCfdi')
  public txtBuscarCfdi!: ElementRef<HTMLElement>;

  toggleMobileSidebar(): void {
    this.mobileSidebarToggled = !this.mobileSidebarToggled;
  }

  scrollHeihgt: number = 0;
  public appSettings = inject(AppSettings);
  private reportsService = inject(ReportsService);
  constructor(private http: HttpClient, private fb: FormBuilder, private fServices: FacturacionService, private contaService: ContabilidadService) {
    this.appSettings.appSidebarMinified = true;
    this.appSettings.appContentFullHeight = true;
    this.appSettings.appContentClass = 'd-flex flex-column';
    this.recalcHeight({ target: window });
  }

  public myForm: FormGroup = this.fb.group({
    busqueda: [''],
    tipoDeComprobante: '',
    estado: '',
    metodoDePago: [''],
    poliza: [''],
    numeroPoliza: [],
    soloDeclaracion: [false],
    fechaInicioString: [''],
    fechaFinString: [''],
    fechaInicio: [Date],
    fechaFin: [Date],
  })

  ngOnInit() {
    this.sendFocus();
    setTimeout(() => {
      this.myForm.reset({
        busqueda: '',
        tipoDeComprobante: '',
        estado: '',
        metodoDePago: '',
        poliza: '',
        numeroPoliza: 0,
        fechaInicio: new Date(),
        fechaFin: new Date(),
        fechaInicioString: moment().format("YYYY-MM-01"),
        fechaFinString: moment().format("YYYY-MM-DD"),
      });
    }, 0);
    this.contaService.obtenerTiposPolizas().subscribe((lista) => {
      this.tiposPoliza = lista;
      console.log(this.tiposPoliza)
    });
  }

  get getActivarOpciones(): boolean {
    return !(this.lista.filter(P => P.seleccionado).length > 0);
  }

  seleccionar(pos: number) {
    this.lista[pos].seleccionado = !this.lista[pos].seleccionado;
    this.lista = [...this.lista];
  }

  ordenar(campo: string) {
    this.lastOrder = campo;
    let order = this.ordenados.filter(P => P.name == campo)[0];
    let ordenado = orderBy(this.lista, [campo], [order.order == "desc" ? "asc" : "desc"]);
    order.order == "asc" ? order.order = "desc" : order.order = "asc";
    this.lastOrderAsc = order.order;
    this.lista = [...ordenado];
  }

  selTodos() {
    if (this.lista.length == 0) {
      return;
    }
    this.todos = !this.todos;
    this.ninguno = !this.ninguno;

    this.lista.map((i: DescargaCfdi) => {
      i.seleccionado = this.todos;
      return i;
    })

    this.lista = [...this.lista];

  }

  selEmitidos() {
    this.lista = [];
    this.emitidos = !this.emitidos;
    this.recibidos = !this.recibidos;
  }

  sendEmail() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.obtenerCorreosCliente(this.myForm.value.Cliente.Id).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      const correos = result.message;
      Swal.fire({
        title: "Envíar email para:",
        input: "text",
        inputValue: correos,
        inputAttributes: { autocapitalize: "off" },
        showCancelButton: true,
        confirmButtonText: "Enviar",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.fServices.sendEmail(this.myForm.value.Id, result.value).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              if (result.success) {
                this.eventsService.publish('home:showAlert', { message: "Se ha enviado por e-mail", cancelButton: false });
              } else {
                this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
              }
            })
          } else {
            this.eventsService.publish('home:showAlert', { message: "Debe de indicar por lo menos un e-mail.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
          }
        }
      });
    });
  }


  validarEstado() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let validar: DescargaCfdi[] = this.lista.filter(P => P.seleccionado);
    if (validar.length > 0) {
      this.validar(0, validar);
    } else {
      this.eventsService.publish('home:showAlert', { message: "Primero selecione los CFDIS que se quieren validar.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
    }
  }

  validar(pos: number, validar: DescargaCfdi[]) {
    const desc: DescargaCfdi = validar[pos];
    this.fServices.verificarEstadoSATUUID(desc.uuid).subscribe((result) => {
      if (pos + 1 < validar.length) {
        const r: CfdiEstadoSAT = JSON.parse(result.message);
        validar[pos].vigente = r.Estado.toLowerCase() == "vigente";
        this.lista = [...validar];
        this.validar(pos + 1, validar);
      } else {
        Swal.fire({ position: 'center', icon: 'success', title: 'Se validaron los CFDI correctamente.', showConfirmButton: false, timer: 5000 });
        this.search();
      }
    });
  }

  downloadCSVFile(csv_data: any) {

    // Create CSV file object and feed our
    // csv_data into it
    const CSVFile = new Blob([csv_data], { type: "text/csv" });

    // Create to temporary link to initiate
    // download process
    let temp_link = document.createElement('a');

    // Download csv file
    temp_link.download = "GfG.csv";
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  selectedCombo(val: string, prop: string) {
    if (val == "TODOS") {
      this.myForm.controls[prop].setValue("");
    } else {
      this.myForm.controls[prop].setValue(val);
    }
  }

  print(uuid: string) {
    if (uuid) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      const params = new HttpParams().set("uuid", uuid);
      this.http.get<Result>(`${this.baseUrl}/Fiscal/ImprimirCfdiRecibido`, { params }).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          this.fServices.printDocument(this.fServices.base64ToArrayBuffer(result.message));
        } else {
          Swal.fire({ text: `${result.message}`, icon: 'error', })
        }
      })
    }
  }

  downloadXML(uuid: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    return this.http.get(`${this.baseUrl}/Fiscal/DescargarXml?uuid=${uuid}&recibido=${this.recibidos}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (d) {
        let blob = new Blob([d], { type: "application/xml" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert('Please disable your Pop-up blocker and try again.');
        }
      }

    })
  }

  sendFocus() {
    setTimeout(() => {
      const txt: any = this.txtBuscarCfdi;
      txt.nativeElement.focus()
    }, 250);
  }

  search() {
    this.todos = false;
    this.ninguno = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("filtro", this.myForm.value.busqueda)
      .set("tipoComprobante", this.myForm.value.tipoDeComprobante)
      .set("estado", this.myForm.value.estado == '' ? 0 : Number(this.myForm.value.estado))
      .set("metodoDePago", this.myForm.value.metodoDePago)
      .set("poliza", this.myForm.value.poliza)
      .set("numeroPoliza", this.myForm.value.numeroPoliza)
      .set("fechaInicio", moment(this.myForm.value.fechaInicioString).format("DD/MM/YYYY"))
      .set("fechaFin", moment(this.myForm.value.fechaFinString).format("DD/MM/YYYY"))
      .set("emitidos", this.emitidos)
      .set("soloDeclaracion", this.myForm.value.soloDeclaracion);
    return this.http.get<DescargaCfdi[]>(`${this.baseUrl}/Fiscal/BusquedaContabilidad`, { params }).subscribe((list) => {
      this.lastOrder = "";
      this.lastOrderAsc = "";
      this.eventsService.publish('home:isLoading', { isLoading: false });
      list.map((item) => {

        switch (item.tipoDeComprobante) {
          case 'i': item.tipoDeComprobante = "INGRESO"; break;
          case 'e': item.tipoDeComprobante = "EGRESO"; break;
          case 't': item.tipoDeComprobante = "TRASLADO"; break;
          case 'p':
            item.total = item.totalPago;
            item.tipoDeComprobante = "PAGO";
            break;
          case 'n': item.tipoDeComprobante = "NÓMINA"; break;
        }

        return item;
      })
      this.lista = list;

    })
  }

  ngAfterViewInit(): void {
    var containerBase = document.getElementById("file-manager-component");
    containerBase!.style.padding = "0px";
    console.log("El ContainerBase", containerBase);
    //this.getCfdis();
  }

  @HostListener('window:resize', ['$event'])
  onChangeHeight(event: any) {
    this.recalcHeight(event);
  }

  recalcHeight(event: any) {
    this.scrollHeihgt = event.target.innerHeight - 178;
  }

  ngOnDestroy() {
    this.appSettings.appSidebarMinified = false;
    this.appSettings.appContentFullHeight = false;
    this.appSettings.appContentClass = '';
  }

  descargarZip() {
    let seleccionados = this.lista.filter(x => x.seleccionado).map(x => x.id).toString();
    this.eventsService.publish('home:isLoading', { isLoading: true });
    return this.http.get(`${this.baseUrl}/Fiscal/DescargarCFDIs?uuids=${seleccionados}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      console.log(d);
      if (d) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        let blob = new Blob([d], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Administración_CFDIs.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    })
  }

  filtros: ReportFilter = {} as ReportFilter;
  imprimirSeleccionados() {
    let seleccionados = this.lista.filter(x => x.seleccionado).map(x => x.id).toString();
    this.filtros = {
      ReportHeader: {
        NombreReporte: seleccionados,
        Fecha1: this.myForm.value.fechaInicioString,
        Fecha2: this.myForm.value.fechaFinString,
        Opc1: this.emitidos
      } as ReportHeader,
      TituloVisor: "Impresión de CFDIs",
      Fecha1: this.myForm.value.fechaInicioString,
      Fecha2: this.myForm.value.fechaFinString,
      NombreExcel: "Administrador CFDI.xlsx",
      FilterOptions: []
    }
    this.reportsService.printReport(this.filtros, '/Fiscal/ImprimirCFDIs');
  }
}
