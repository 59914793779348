import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DescargaCfdi } from '../../interfaces/solicitud.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { GuiColumnAlign, GuiRowClass, GuiRowStyle, GuiSorting } from '@generic-ui/ngx-grid';
import Swal from 'sweetalert2';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Result } from 'src/app/auth/interfaces';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { ModalService } from 'src/app/service/modal.service';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { CfdiEstadoSAT } from 'src/app/ventas/interfaces/venta.interface';

@Component({
  selector: 'app-ctrl-buscador-cfdi',
  templateUrl: './ctrl-buscador-cfdi.component.html',
  styles: [
  ]
})
export class CtrlBuscadorCfdiComponent implements OnInit {
  sorting: GuiSorting = { enabled: true };
  public GuiColumnAlign = GuiColumnAlign;
  private readonly baseUrl: string = environment.baseUrlApi;
  @ViewChild('txtBuscarCfdi')
  public txtBuscarCfdi!: ElementRef<TextBoxComponent>;
  lista: DescargaCfdi[] = []
  listaAgregados: DescargaCfdi[] = []
  searching: boolean = false;
  private eventsService = inject(EventsService);
  margen: string = "200px";
  public appSettings = inject(AppSettings);

  @Input()
  public rfcEmisor: string = "";
  @Input()
  public mostrarAgregados: boolean = true;
  @Input()
  public title: string = "";
  @Input()
  public uuidsAgregados: string = "";
  @Input()
  public anio: number = 0;
  @Input()
  public mes: number = 0;

  @Input()
  set setIsNew(isNew: boolean) {
    if (isNew) {
      var lastDay = new Date(this.anio, this.mes + 1, 0);
      this.myForm.reset({
        busqueda: '',
        tipoDeComprobante: '',
        emitidorecibido: 'Recibidos',
        fechaInicio: new Date(this.anio, this.mes, 1),
        fechaFin: new Date(this.anio, this.mes, lastDay.getDate()),
      });
      this.sendFocus();
    }
  }

  // rowStyle: GuiRowStyle = {
  //   styleFunction: (data: DescargaCfdi, index: number) => {
  //     if (data.seleccionado) {
  //       return 'background-color:#ffed0a;font-weight:bold;';
  //     }
  //     return 'background-color:transparent;font-weight:normal;';
  //   }
  // };


  public myForm: FormGroup = this.fb.group({
    busqueda: [''],
    tipoDeComprobante: '',
    fechaInicio: [Date],
    fechaFin: [Date],
    emitidorecibido: 'Recibidos'
  })


  constructor(private fb: FormBuilder, private http: HttpClient, private fServices: FacturacionService, private ms: ModalService) {

  }

  searchEnter() {
    setTimeout(() => { this.search(); }, 100);
  }

  get getGlobalStyle(): string {
    if (this.anio == 0) return "";
    return "height:540px;z-index:9;width:1200px;";
  }


  get getDivStyle(): string {
    return `border: 1px dotted;width: 891px;height:${(this.anio > 0 ? '308' : '575')}px;margin-left: 5px;padding:0px;`;
  }

  selectedCombo(ent: ComboBoxEntity, type: string) {
    if (type == "TipoDeComprobante") {
      this.myForm.controls['tipoDeComprobante'].setValue(ent ? ent.Clave : '');
    }
    this.searchEnter();
  }

  downloadXML(uuid: string) {
    this.searching = true;
    return this.http.get(`${this.baseUrl}/Fiscal/DescargarXml?uuid=${uuid}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.searching = false;
      if (d) {
        let blob = new Blob([d], { type: "application/xml" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert('Please disable your Pop-up blocker and try again.');
        }
      }

    })
  }

  search() {
    this.searching = true;
    const params = new HttpParams().set("filtro", this.myForm.value.busqueda)
      .set("rfcEmisor", this.rfcEmisor)
      .set("tipoComprobante", this.myForm.value.tipoDeComprobante)
      .set("fechaInicio", moment(this.myForm.value.fechaInicio).format("DD/MM/YYYY"))
      .set("fechaFin", moment(this.myForm.value.fechaFin).format("DD/MM/YYYY"))
      .set("emitidos", this.myForm.value.emitidorecibido == "Emitidos");
    return this.http.get<DescargaCfdi[]>(`${this.baseUrl}/Fiscal/BusquedaContabilidadSinPoliza`, { params }).subscribe((list) => {
      this.searching = false;
      list.map((item) => {

        switch (item.tipoDeComprobante) {
          case 'i': item.tipoDeComprobante = "INGRESO"; break;
          case 'e': item.tipoDeComprobante = "EGRESO"; break;
          case 't': item.tipoDeComprobante = "TRASLADO"; break;
          case 'p':
            item.total = item.totalPago;
            item.tipoDeComprobante = "PAGO";
            break;
          case 'n': item.tipoDeComprobante = "NÓMINA"; break;
        }

        return item;
      })
      this.lista = [...list];

    })
  }

  ngOnInit(): void {
    if (this.appSettings.appSidebarMinified) {
      this.margen = "100px";
    }
    this.sendFocus();
    debugger
    //if (this.anio != 0 && this.mes != 0) {
    this.searchEnter();
    this.getAdded();
    // } else {
    //   const date = new Date();
    //   this.myForm.reset({
    //     tipoDeComprobante: '',
    //     busqueda: '',
    //     emitidorecibido: 'Recibidos',
    //     fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
    //     //fechaInicio: new Date(date.getFullYear(), 0, 1),
    //     fechaFin: date,
    //   });
    // }
  }

  clearAdded() {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar todos los CFDI (' + this.listaAgregados.length + ')?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.listaAgregados = [];
          this.listaAgregados = [...this.listaAgregados];
        }
      }
    });
  }

  deleteRow(index: number) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el CFDI?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.listaAgregados.splice(index, 1);
          this.listaAgregados = [...this.listaAgregados];
        }
      }
    });
  }

  print(uuid: string) {
    if (uuid) {
      this.searching = true;
      const params = new HttpParams().set("uuid", uuid);
      this.http.get<Result>(`${this.baseUrl}/Fiscal/ImprimirCfdiRecibido`, { params }).subscribe((result) => {
        this.searching = false;
        if (result.success) {
          this.fServices.printDocument(this.fServices.base64ToArrayBuffer(result.message));
        } else {
          Swal.fire({ text: `${result.message}`, icon: 'error', })
        }
      })
    }
  }

  getAdded(): void {
    if (this.uuidsAgregados?.length > 0) {
      this.searching = true;
      const params = new HttpParams().set("uuids", this.uuidsAgregados);
      this.http.get<DescargaCfdi[]>(`${this.baseUrl}/Fiscal/ObtenerPorUuids`, { params }).subscribe((list) => {
        this.searching = false;
        list.map((item) => {
          switch (item.tipoDeComprobante) {
            case 'i': item.tipoDeComprobante = "INGRESO"; break;
            case 'e': item.tipoDeComprobante = "EGRESO"; break;
            case 't': item.tipoDeComprobante = "TRASLADO"; break;
            case 'p':
              item.total = item.totalPago;
              item.tipoDeComprobante = "PAGO";
              break;
            case 'n': item.tipoDeComprobante = "NÓMINA"; break;
          }

          return item;
        })
        this.listaAgregados = list;
      })
    }
  }

  seleccionar(index: number, type: number) {
    if (type == 1) {
      this.lista[index].seleccionado = !this.lista[index].seleccionado;
      this.lista = [...this.lista];
    } else {
      this.listaAgregados[index].seleccionado = !this.listaAgregados[index].seleccionado;
      this.listaAgregados = [...this.listaAgregados];
    }
  }

  accept() {
    let enBusqueda = this.lista.filter(P => P.seleccionado);
    if (this.listaAgregados) {
      this.listaAgregados.forEach((i) => {
        let c = enBusqueda.filter(P => P.uuid == i.uuid).length;
        if (c == 0) {
          enBusqueda = [...enBusqueda, i];
        }
      })
    }
    if (enBusqueda.length > 0) {

      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.validar(0, enBusqueda, (lista: DescargaCfdi[]) => {
        let novig = lista.filter(P => !P.vigente).length;
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (novig == 0) {
          this.ms.closeModal(enBusqueda);
        } else {
          this.eventsService.publish('home:showAlert', { message: `Se realizó la validación de los CFDI seleccionados y algunos se encuentran cancelados, verifique.`, cancelButton: false });
          this.search();
        }
      });
    } else {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.ms.closeModal([]);
      return;
    }
  }

  selectedComboEmitido(val: string) {
    if (val == "TODOS") {
      this.myForm.controls['emitidorecibido'].setValue("");
    } else {
      this.myForm.controls['emitidorecibido'].setValue(val);
    }
  }

  validar(pos: number, validar: DescargaCfdi[], cb: any) {
    const desc: DescargaCfdi = validar[pos];
    this.fServices.verificarEstadoSATUUID(desc.uuid).subscribe((result) => {
      if (pos + 1 < validar.length) {
        const r: CfdiEstadoSAT = JSON.parse(result.message);
        validar[pos].vigente = r.Estado.toLowerCase() == "vigente";
        this.lista = [...validar];
        this.validar(pos + 1, validar, cb);
      } else {
        cb(validar);
      }
    });
  }

  cancel() {
    this.ms.closeModal(null);
  }

  sendFocus() {
    setTimeout(() => {
      const txt: any = this.txtBuscarCfdi;
      txt.tagInput.nativeElement.focus()
    }, 250);
  }

}
