
export interface FilterOptions {
  Campo: string,
  Etiqueta: string,
  Tipo: TypeFilter,
  Nombre?: string,
  TipoNiveles?: LevelType,
  Valor?: string
  ListaItems?: ReportFilterListItem[]
  Entidad?: string,
  tabIndex?: number,
  BotonBusqueda?: boolean,
  Filtro?: string,
  MultiClave?: boolean
  PropiedadRetorna?: string
  HelpLine?: string;
  Enabled?: boolean;
  Placeholder?: string;
  IgnorarParaFiltro?:boolean;
  EsClienteCabecera?:boolean;
  EsCliente?:boolean
}

export enum LevelType {
  lineSubLine,
  productFamily,
  account,
  costAccount
}


export enum TypeFilter {
  number,
  date,
  boolean,
  text,
  list,
  entityNumber,
  entityString,
  levels,
  colorsPercent,
  levelsAccount,
  levelsCenCost,
}

// export interface ReportFilterOptions{
//   number,
//   date,
//   boolean,
//   text,
//   list
// }




export interface ReportFilterList {
  Clave?: string,
  Nombre?: string,
  ListaItems?: ReportFilterListItem[]
}

export interface ReportFilterListItem {
  Nombre?: string,
  Id?: string,
  Sql?:string
}

export interface ReportFilterContentList {
  Nombre: string,
  Id?: number
}

export interface ReportFilterOrderList {
  Nombre: string,
  Orden: string
  Id?: number,
  Agrupador?:string
}

export interface ReportFilter {
  Desglose?: string,
  DesgloseId?: number,
  DesgloseLista?: ReportFilterContentList[],
  Ordenado?: string,
  OrdenadoId?: number,
  OrdenadoLista?: ReportFilterOrderList[],
  OrdenadoNombre?:string,
  LuegoPor?: string,
  LuegoPorId?: number,
  LuegoPorAgrupador?: string,
  LuegoPorLista?: ReportFilterOrderList[],
  LuegoPorNombre?:string,
  DespuesPor?: string,
  DespuesPorId?: number,
  DespuesPorLista?: ReportFilterOrderList[],
  DespuesPorNombre?:string,

  Fecha1?: Date,
  Fecha2?: Date,
  NombreReporte?: string,
  TituloVisor: string,
  NombreExcel: string,
  FilterOptions?: FilterOptions[],
  ReportHeader: ReportHeader,
  ExportarExcel?: boolean,
  menuId?: string,
  EsContabilidad?: boolean,
  NombreEmpresa?: string,
  NombrePredefinido?:string
}




export interface ReportHeader {
  Fecha1?: Date,
  Fecha2?: Date,
  Cantidad?: number;
  IdOrdenEnvioProduccion?: number,
  NombreReporte?: string,
  Dato1?: string,
  Opc1?: boolean,
  Opc2?: boolean,
  Opc3?: boolean,
  Opc4?: boolean,
  Opc5?: boolean,
  Opc6?: boolean,
  Opc7?: boolean,
  Opc8?: boolean,
  Num1?: number,
  Num2?: number,
  Num3?: number,
  Num4?: number,
  Num5?: number,
  Num6?: number,
  Num7?: number,
  Num8?: number,
  Num9?: number,
  Mes1?: number,
  Mes2?: number,
  Anio1?: number,
  Anio2?: number,
  FechaCorte1?: Date
  FechaCorte2?: Date
}


